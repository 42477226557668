import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { getSearchParamsDeviceType, useDeviceType } from '../../util/hooks/useDeviceType'
import { GlobalContext } from '../../GlobalState'
import { getSearchParamsScopeType, useScopeType } from '../../util/hooks/useScopeType'
import {
  getSearchParamsFilterContentEventsExists,
  useFilterContentEventsExists,
} from '../../util/hooks/cookie/useFilterContentEventsExists'
import { ContextType, useContextState } from './state'
import { useGoalList } from '../../util/hooks/api/Goal/useGoalList'
import { useTeamPv } from '../../util/hooks/api/useTeamPv'
import { getSearchParamsGoalId, useGoalId } from '../../util/hooks/useGoalId'
import { ProjectContext } from '../../pages/ProjectRoot'
import { getDateStringYM } from '../../util/Date'

export const ReportContext = React.createContext<ContextType>(null as any)

interface Props extends RouteComponentProps {
  readonly children?: React.ReactNode | React.ReactNode[]
}

/**
 * レポートで共通使用する state, actions をグローバルで使用できるよう Provider で定義する
 *
 * @param {Object} props - The props object containing the children component.
 * @param {React.ReactNode} props.children - The child component(s) to be wrapped.
 */
export function ReportProvider({ children }: Props) {
  const { actions: globalActions } = React.useContext(GlobalContext)
  const {
    state: { projectId },
  } = React.useContext(ProjectContext)

  const { data: goalList } = useGoalList({ projectId })

  const { setFilterContentEventsExists } = useFilterContentEventsExists()
  const { setScopeType } = useScopeType()
  const { setDeviceType } = useDeviceType()
  const { setGoalId } = useGoalId({ projectId })
  const { data: teamPvData } = useTeamPv({ yearMonth: getDateStringYM(new Date(), '') })

  const { state, actions } = useContextState()

  const [isCompleted, setIsCompleted] = React.useState(false)

  React.useEffect(() => {
    if (!goalList || !teamPvData) return

    const scopeType = getSearchParamsScopeType()
    if (scopeType !== null) setScopeType(scopeType)

    const filterContentEventsExists = getSearchParamsFilterContentEventsExists()
    if (filterContentEventsExists !== null) setFilterContentEventsExists(filterContentEventsExists)

    const deviceType = getSearchParamsDeviceType()
    if (deviceType !== null) setDeviceType(deviceType)

    const goalId = getSearchParamsGoalId(goalList)
    if (goalId !== null) setGoalId(goalId)

    const searchParams = new URLSearchParams(window.location.search)
    globalActions.setReportUrlParam(searchParams)
    actions.setValues(searchParams, goalList, teamPvData)

    setIsCompleted(true)
  }, [goalList, teamPvData])

  if (!isCompleted) return null

  return <ReportContext.Provider value={{ state, actions }}>{children}</ReportContext.Provider>
}
