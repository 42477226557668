import { PageReportItem, PageReportSortKey, PageReportSortState } from '../../../components/grid/PageGridItem'
import { Percent } from '../../Parse'
import { getScaledPercent } from '../../getScaledPercent'
import { CalenderState } from '../../../components/common/DayPickerRange'
import { NarrowDownState, SearchOptions, MatchMap } from '../../../components/filter/PageReportNarrowDown'
import { SCOPE_TYPE, ScopeType, useScopeType } from '../useScopeType'
import { CustomFilterState } from './Filter/types'
import { getDateStringYMD } from '../../Date'
import { getScopeTypeApiValue } from '../../getScopeTypeApiValue'
import { makeReportFilters } from '../../makeReportFilters'
import { request } from '../../request'
import { useQuery } from '@tanstack/react-query'
import { PAGE_REPORT_QUERY_KEY } from './constants'
import { DeviceType, getDeviceLayoutNumber, useDeviceType } from '../useDeviceType'
import { useFilterContentEventsExists } from '../cookie/useFilterContentEventsExists'
import { useGoalList } from './Goal/useGoalList'
import { useGoalId } from '../useGoalId'

export interface PageReportItemResponse {
  readonly id: number | null
  readonly url: string
  readonly title: string
  readonly page_sessions: number
  readonly page_users: number
  readonly page_landing_count: number
  readonly page_bounce_rate: number
  readonly page_exit_rate: number
  readonly page_conversions: number
  readonly page_conversions_rate: number
  readonly content_events_exists: boolean
}

export interface PageReportsResponse {
  readonly count: number
  readonly all_sessions: number
  readonly all_users: number
  readonly all_landing_count: number
  readonly all_bounce_rate: number
  readonly all_exit_rate: number
  readonly all_conversions: number
  readonly all_conversions_rate: number
  readonly results: Array<PageReportItemResponse>
}

export interface PageReportsSummary {
  readonly allSessions: number
  readonly allUsers: number
  readonly allLandingCount: number
  readonly allBounceRate: number
  readonly allExitRate: number
  readonly allConversions: number
  readonly allConversionsRate: number
}

export interface PageReportsData {
  count: number
  pageReportsSummary: PageReportsSummary
  reportItems: PageReportItem[]
}

export const INIT_PAGE_REPORTS_SUMMARY = {
  allSessions: 0,
  allUsers: 0,
  allLandingCount: 0,
  allBounceRate: 0,
  allExitRate: 0,
  allConversions: 0,
  allConversionsRate: 0,
} as const

const INIT_PAGE_REPORTS_DATA = {
  count: 0,
  pageReportsSummary: INIT_PAGE_REPORTS_SUMMARY,
  reportItems: [],
}

export const INIT_SORT_STATE: PageReportSortState = {
  id: 'none',
  url: 'none',
  title: 'none',
  page_sessions: 'none',
  page_users: 'none',
  page_conversions: 'none',
  page_conversions_rate: 'none',
  page_landing_count: 'none',
  page_exit_rate: 'none',
  page_bounce_rate: 'none',
}

export const usePageReport = ({
  projectId,
  calenderState,
  limit,
  offset,
  sortState,
  searchText,
  narrowDownState,
  customFilterState,
  enabled = true,
}: {
  projectId: string
  calenderState: CalenderState
  limit: number
  offset: number
  sortState: PageReportSortState
  searchText: string
  narrowDownState: NarrowDownState[]
  customFilterState: CustomFilterState[]
  enabled?: boolean
}) => {
  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { filterContentEventsExists } = useFilterContentEventsExists()
  const { goalId } = useGoalId({ projectId: Number(projectId) })
  const { getGoal } = useGoalList({ projectId: Number(projectId) })
  const goal = getGoal(goalId)

  const queryKey = [
    PAGE_REPORT_QUERY_KEY,
    {
      projectId,
      scopeType,
      deviceType,
      goal,
      calenderState,
      limit,
      offset,
      sortState,
      searchText,
      narrowDownState,
      customFilterState,
      filterContentEventsExists,
    },
  ]

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const requestBody = makePageReportRequestBody(
        scopeType,
        deviceType,
        goalId,
        calenderState,
        limit,
        offset,
        sortState,
        searchText,
        narrowDownState,
        customFilterState,
        filterContentEventsExists,
      )
      return await request<PageReportsResponse>('POST', `/api/projects/${projectId}/page_reports/`, true, requestBody)
    },
    select: (reports) => {
      if (reports.results.length === 0) return INIT_PAGE_REPORTS_DATA

      const minPageSessions: number = Math.min(...reports.results.map((report) => report.page_sessions))
      const maxPageSessions: number = Math.max(...reports.results.map((report) => report.page_sessions))
      const minPageUsers: number = Math.min(...reports.results.map((report) => report.page_users))
      const maxPageUsers: number = Math.max(...reports.results.map((report) => report.page_users))
      const minPageLandingCount: number = Math.min(...reports.results.map((report) => report.page_landing_count))
      const maxPageLandingCount: number = Math.max(...reports.results.map((report) => report.page_landing_count))
      const minPageBounceRate: number = Math.min(...reports.results.map((report) => report.page_bounce_rate))
      const maxPageBounceRate: number = Math.max(...reports.results.map((report) => report.page_bounce_rate))
      const minPageExitRate: number = Math.min(...reports.results.map((report) => report.page_exit_rate))
      const maxPageExitRate: number = Math.max(...reports.results.map((report) => report.page_exit_rate))
      const minPageConversions: number = Math.min(...reports.results.map((report) => report.page_conversions))
      const maxPageConversions: number = Math.max(...reports.results.map((report) => report.page_conversions))
      const minPageConversionsRate: number = Math.min(...reports.results.map((report) => report.page_conversions_rate))
      const maxPageConversionsRate: number = Math.max(...reports.results.map((report) => report.page_conversions_rate))

      const formatted: PageReportsData = {
        count: reports.count,
        pageReportsSummary: {
          allSessions: reports.all_sessions,
          allUsers: reports.all_users,
          allLandingCount: reports.all_landing_count,
          allBounceRate: Percent.parse(reports.all_bounce_rate),
          allExitRate: Percent.parse(reports.all_exit_rate),
          allConversions: reports.all_conversions,
          allConversionsRate: Percent.parse(reports.all_conversions_rate),
        },
        reportItems: reports.results.map((report) => ({
          id: report.id,
          url: report.url,
          title: report.title,
          pageSessions: report.page_sessions,
          pageSessionsScaledPercent: getScaledPercent(report.page_sessions, minPageSessions, maxPageSessions),
          pageSessionsOverallRatio: Percent.parse(report.page_sessions / reports.all_sessions),

          pageUsers: report.page_users,
          pageUsersScaledPercent: getScaledPercent(report.page_users, minPageUsers, maxPageUsers),
          pageUsersOverallRatio: Percent.parse(report.page_users / reports.all_users),

          pageLandingCount: report.page_landing_count,
          pageLandingCountScaledPercent: getScaledPercent(
            report.page_landing_count,
            minPageLandingCount,
            maxPageLandingCount,
          ),
          pageLandingCountOverallRatio: Percent.parse(report.page_landing_count / reports.all_landing_count),

          pageBounceRate: Percent.parse(report.page_bounce_rate),
          pageBounceRateScaledPercent: getScaledPercent(report.page_bounce_rate, minPageBounceRate, maxPageBounceRate),

          pageExitRate: Percent.parse(report.page_exit_rate),
          pageExitRateScaledPercent: getScaledPercent(report.page_exit_rate, minPageExitRate, maxPageExitRate),

          pageConversions: report.page_conversions,
          pageConversionsScaledPercent: getScaledPercent(
            report.page_conversions,
            minPageConversions,
            maxPageConversions,
          ),
          pageConversionsOverallRatio: Percent.parse(report.page_conversions / reports.all_conversions),

          pageConversionsRate: Percent.parse(report.page_conversions_rate),
          pageConversionsRateScaledPercent: getScaledPercent(
            report.page_conversions_rate,
            minPageConversionsRate,
            maxPageConversionsRate,
          ),
          contentEventsExists: report.content_events_exists,
        })),
      }
      return formatted
    },
    staleTime: 1000 * 60 * 5,
    enabled: enabled && !!goal,
  })

  return {
    ...queryResult,
  }
}

export const makePageReportRequestBody = (
  scopeType: ScopeType,
  deviceType: DeviceType,
  goalId: number,
  calenderState: CalenderState,
  limit: number | undefined,
  offset: number | undefined,
  sortState: PageReportSortState,
  searchText: string,
  narrowDownState: NarrowDownState[],
  customFilterState: CustomFilterState[],
  filterContentEventsExists: boolean,
) => {
  let json = {
    goal_id: goalId,
    page_layout: getDeviceLayoutNumber(deviceType),
    search_from: getDateStringYMD(calenderState.startDate, '-'),
    search_to: getDateStringYMD(calenderState.endDate, '-'),
    scope_type: getScopeTypeApiValue(scopeType),
    limit,
    offset,
    filters: makeReportFilters(customFilterState),
    filter_content_events_exists: filterContentEventsExists,
  }

  // ソート
  const newSortState: PageReportSortState = switchSortStateByScopeType(sortState, scopeType)
  const { key, reverse } = getSortParam(newSortState)
  if (key !== 'none') {
    const sort = { sort_key: key, sort_reverse: reverse }
    json = { ...json, ...sort }
  }

  // 簡易絞り込み
  if (searchText !== '') {
    const search = { page: searchText }
    json = { ...json, ...search }
  }

  // 高度な絞り込み
  if (narrowDownState.length > 0) {
    json = { ...json, ...getNarrowDown(narrowDownState) }
  }

  return JSON.stringify(json)
}

const switchSortStateByScopeType = (sortState: PageReportSortState, scopeType: ScopeType): PageReportSortState => {
  if (scopeType === SCOPE_TYPE.SESSION && sortState.page_users !== 'none') {
    return {
      ...sortState,
      page_sessions: sortState.page_users,
      page_users: 'none',
    }
  }
  if (scopeType === SCOPE_TYPE.USER && sortState.page_sessions !== 'none') {
    return {
      ...sortState,
      page_sessions: 'none',
      page_users: sortState.page_sessions,
    }
  } else {
    return sortState
  }
}

const getSortParam = (state: PageReportSortState): { key: string; reverse: number } => {
  for (const e in state) {
    const elem = e as PageReportSortKey
    if (state[elem] !== 'none') {
      return { key: e, reverse: state[elem] === 'up' ? 0 : 1 }
    }
  }
  return { key: 'none', reverse: 0 }
}

const getNarrowDown = (
  narrowDownState: NarrowDownState[],
): {
  advanced_page_filters: {
    name: string
    value: string
    match_type: string
    included: boolean
  }[]
} => {
  return {
    advanced_page_filters: narrowDownState.map((state) => {
      return {
        name: state.condition[1] === SearchOptions.Option2.url ? 'url' : 'title',
        value: state.searchValue,
        match_type: MatchMap[state.condition[2]],
        included: state.condition[0] === SearchOptions.Option1.match ? true : false,
      }
    }),
  }
}

export const makeErrorMessage = (
  searchText: string,
  narrowDown: NarrowDownState[],
  customFilterState: CustomFilterState[],
): string => {
  // 検索条件がある場合
  if (searchText.length > 0 || narrowDown.length > 0 || customFilterState.length > 0) {
    return 'ご指定の条件に一致するデータはありません。条件を変えて再度検索してください。'
  }
  return '検索対象期間にはデータがありません。'
}
