import * as React from 'react'
import styled from 'styled-components'

import { BaseHeaderCell } from './BaseCell'
import { CELL_BACKGROUND_COLORS } from '../../DataTable'

export function ContentNameHeaderCell() {
  return <Cell>コンテンツ名</Cell>
}

const Cell = styled(BaseHeaderCell)`
  background-color: ${CELL_BACKGROUND_COLORS.CONTENT};
`
