import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { Popup } from './Popup'
import { ProjectContext } from '../../../pages/ProjectRoot'
import { useFilterTextTransform } from '../../../util/hooks/api/Filter/useFilterTextTransform'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'
import { useSelector } from 'react-redux'
import { navigationState } from '../../../redux/navigationSlice'

export const Filters: React.FC = () => {
  const {
    state: { projectId },
  } = React.useContext(ProjectContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)

  const { opened: sideNaviOpened } = useSelector(navigationState)

  const { data: filterTexts = [] } = useFilterTextTransform({
    projectId,
    payload: customFilterState,
  })

  const isSummarized = filterTexts.length >= 3
  const slicePosition = isSummarized ? 2 : filterTexts.length
  const texts = filterTexts.slice(0, slicePosition)
  const summarizedTexts = filterTexts.slice(slicePosition)
  const summarizedPopupContents = summarizedTexts.map((text) => ({
    title: `${text.scopeLabel}: ${text.includedLabel}`,
    items: text.conditionTexts,
  }))

  return (
    <Container>
      <Title>
        フィルタ
        <br />
        適用中
      </Title>
      <FilterTextsContainer>
        {texts.map((text, i) => {
          const popupContents = [{ title: `${text.scopeLabel}: ${text.includedLabel}`, items: text.conditionTexts }]
          return (
            <Popup contents={popupContents} key={`${text}${i}`}>
              <FilterText
                sideNaviOpened={sideNaviOpened}
              >{`${text.scopeLabel}: ${text.includedLabel} / ${text.conditionTexts}`}</FilterText>
            </Popup>
          )
        })}
        {isSummarized && (
          <Popup contents={summarizedPopupContents} direction="right">
            <FilterTextTotal>他{summarizedTexts.length}件</FilterTextTotal>
          </Popup>
        )}
      </FilterTextsContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

const FilterTextsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
`

const FilterTextTotal = styled.div`
  border-radius: 15px;
  font-size: 10px;
  line-height: 1.5;
  padding: 6px 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  background-color: ${colors.yellow2};
`

const FilterText = styled(FilterTextTotal)<{ sideNaviOpened: boolean }>`
  /* memo: vwとpxの数値は、画面を見ながら調整した感覚値。 */
  /* 変数を使った設定はできなかった。例えばlayout.navigationWidth(240px) と layout.navigationCloseWidth(48px) では計算できなかった */
  max-width: calc(50vw - ${({ sideNaviOpened }) => (sideNaviOpened ? '518px' : '423px')});
  min-width: 4em;
  font-size: 12px;
`

const Title = styled.div`
  text-align-last: justify;
  white-space: nowrap;
  color: ${colors.contentBlue.blue7};
  font-size: 10px;
  line-height: 1.5;
  font-weight: bold;
`
