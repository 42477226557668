import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { toLocaleStringWithCeil } from '../../../util/toLocaleStringWithCeil'
import { toMs } from '../../../util/Parse'
import { BaseCell } from '../cells/BaseCell'
import { BaseRow } from './BaseRow'
import { ContentsSummary } from '../../../util/hooks/api/useContentReport'

interface Props {
  readonly contentsSummary: ContentsSummary
}

export function SummaryRow({
  contentsSummary: { viewCount, viewRate, viewSecond, goalCount, goalRate, clickCount, clickRate },
}: Props) {
  return (
    <BaseRow>
      <ContentCell>すべてのコンテンツ平均</ContentCell>
      <NumberCell>{toLocaleStringWithCeil(viewCount)}</NumberCell>
      <NumberCell>{viewRate.toFixed(1)}%</NumberCell>
      <NumberCell>{toMs(Math.floor(viewSecond))}</NumberCell>
      <NumberCell>{toLocaleStringWithCeil(goalCount)}</NumberCell>
      <NumberCell>{goalRate.toFixed(2)}%</NumberCell>
      <NumberCell>{toLocaleStringWithCeil(clickCount)}</NumberCell>
      <NumberCell>{clickRate.toFixed(1)}%</NumberCell>
    </BaseRow>
  )
}

const SummaryBaseCell = styled(BaseCell)`
  background-color: ${colors.gray100};
  font-weight: bold;
`

const ContentCell = styled(SummaryBaseCell)`
  grid-column-start: 1;
  grid-column-end: 4;
`

const NumberCell = styled(SummaryBaseCell)`
  justify-content: flex-end;
`
