import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { AddButton, CancelButton, NormalButton } from '../common/Button'
import { Select } from '../common/Select'
import { Input } from '../common/Input'
import { Close as CloseIcon } from '@styled-icons/evaicons-solid/Close'

export const narrowDownLayout = {
  width: '780px',
  innerWidth: '700px', // width - ( sideMargin * 2 )
  innerHeight: '240px',
  headerHeight: '60px',
  height: '430px',
  closeIcon: '30',
  closeX: '370px', // ( width - iconSize ) * 0.5
  closeY: '225px',
  sideMargin: '40px',
  itemHeight: '40px',
}

export const SearchOptions = {
  Option1: {
    match: 0, // 一致
    exclusion: 1, // 除外
  },
  Option2: {
    url: 0,
    title: 1,
  },
  Option3: {
    full: 0, // 完全一致
    head: 1, // 先頭一致
    partial: 2, // 部分一致
    regexp: 3, // 正規表現一致
  },
}

// SearchOptions.Option3に対応したmap
export const MatchMap: { [key: number]: string } = {
  0: 'full',
  1: 'head',
  2: 'partial',
  3: 'regexp',
}

const selectOptions1 = [
  { label: '一致', value: SearchOptions.Option1.match },
  { label: '除外', value: SearchOptions.Option1.exclusion },
]

const selectOptions2 = [
  { label: 'URL', value: SearchOptions.Option2.url },
  { label: 'タイトル', value: SearchOptions.Option2.title },
]

const selectOptions3 = [
  { label: '完全一致', value: SearchOptions.Option3.full },
  { label: '先頭一致', value: SearchOptions.Option3.head },
  { label: '部分一致', value: SearchOptions.Option3.partial },
  { label: '正規表現一致', value: SearchOptions.Option3.regexp },
]

export interface NarrowDownState {
  condition: number[]
  searchValue: string
}

interface Props {
  readonly opened: boolean
  readonly disabled?: boolean
  readonly items: NarrowDownState[]
  readonly onClose: () => void
  readonly onCancel?: () => void
  readonly onApply?: () => void
  readonly onAdd?: () => void
  readonly onDelete?: (index: number) => void
  readonly onReset?: () => void
  readonly onOptionSelect?: (event: React.ChangeEvent<HTMLSelectElement>, index: number, condition: number) => void
  readonly onInputChange?: (event: React.FormEvent<HTMLInputElement>, index: number) => void
}

export function PageReportNarrowDown(props: Props) {
  const {
    opened,
    disabled,
    items,
    onClose,
    onCancel,
    onApply,
    onAdd,
    onDelete,
    onReset,
    onOptionSelect,
    onInputChange,
  } = props
  const element = items.map((item, index) => {
    return (
      <form data-id={index} key={`content-${index}`}>
        <Content>
          <DesignedSelect
            defaultValue={item.condition[0]}
            options={selectOptions1}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onOptionSelect!(e, index, 0)}
          />
          <DesignedSelect
            defaultValue={item.condition[1]}
            options={selectOptions2}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onOptionSelect!(e, index, 1)}
          />
          <DesignedSelect
            defaultValue={item.condition[2]}
            options={selectOptions3}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onOptionSelect!(e, index, 2)}
          />
          <DesignedInput type="text" value={item.searchValue} onChange={(e) => onInputChange!(e, index)} />
          <div>
            <CloseButton onClick={() => onDelete!(index)} />
          </div>
        </Content>
        {items.length > 0 && items.length !== index + 1 && <AndLabel>AND</AndLabel>}
      </form>
    )
  })
  return (
    <Modal isOpen={opened} onClose={onClose} title="検索オプション" width={780}>
      <ModalContentWrapper>
        {items.length > 0 && (
          <OptionBox>
            <CancelButton onClick={onReset}>リセット</CancelButton>
          </OptionBox>
        )}
        <ScrollArea>
          {element}
          <div style={{ marginTop: '1rem' }}>
            <AddButton onClick={onAdd}>条件を追加</AddButton>
          </div>
        </ScrollArea>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <NormalButton disabled={disabled} onClick={onApply}>
          検索
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const OptionBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
`

const ScrollArea = styled.div`
  display: flex;
  width: 100%;
  max-height: ${narrowDownLayout.innerHeight};
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
`

const Content = styled.div`
  display: flex;
  align-items: center;
`

const AndLabel = styled.div`
  margin: 0.5rem 0;
  font-size: 0.8rem;
  font-weight: 600;
`

const DesignedSelect = styled(Select)`
  height: ${narrowDownLayout.itemHeight};
  font-size: 0.8rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;

  & + & {
    margin-left: 0.5rem;
  }
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: ${narrowDownLayout.itemHeight};
  margin-left: 0.5rem;
`

const CloseButton = styled(CloseIcon).attrs({
  size: 28,
  color: `${colors.gray500}`,
})`
  margin-left: 0.5rem;
  cursor: pointer;
`
