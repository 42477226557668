import * as React from 'react'
import { State, Actions, TAB_INDEX } from '../../../pages/ContentReport/state'
import { SingleContentRegister } from './SingleContentRegister'
import { MultipleContentRegister } from './MultipleContentRegister'
import { MultipleTagsRegister } from './MultipleTagsRegister'
import { ContentTag, useContentReport } from '../../../util/hooks/api/useContentReport'
import { useContentGroupReport } from '../../../util/hooks/api/useContentGroupReport'
import { useToast } from '../../../util/hooks/useToast'
import { UpdateMultipleContentResponse } from '../../../util/hooks/api/useUpdateMultipleContent'
import { ReportContext } from '../../../contexts/ReportProvider'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'

interface Props {
  readonly state: State
  readonly actions: Actions
  readonly canAddManagedContent?: boolean // コンテンツ登録用権限
  readonly canChangeManagedContent?: boolean // コンテンツ登録用権限
  readonly projectId: string
  readonly reportId: string
}

export function ContentRegisterModal(props: Props) {
  const {
    projectId,
    reportId,
    state: {
      allContentsState,
      contentGroupState,
      editingMultipleContents,
      editingMultipleTags,
      selectedContentIds,
      regist: { selectedId },
      selectedTabIndex,
    },
    actions: { onCompleteMultipleContent, onCompleteMultipleTags, regist },
  } = props

  const {
    state: { calenderState },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)

  const { openToast } = useToast()

  const isAllContentTabView = selectedTabIndex === TAB_INDEX.ALL_CONTENTS
  const { data, setContentReport, invalidateContentReport, resetContentReport } = useContentReport({
    projectId,
    reportId,
    calenderState,
    customFilterState,
    sortState: allContentsState.sortState,
    enabled: isAllContentTabView,
  })

  const { resetContentGroupReport } = useContentGroupReport({
    projectId,
    reportId,
    calenderState,
    customFilterState,
    sortState: contentGroupState.sortState,
    enabled: !isAllContentTabView,
  })

  if (!data) return <></>

  if (selectedId) {
    // コンテンツ登録変更
    const selectedContent = data.contents.find((item) => item.id === selectedId)
    if (!selectedContent) return null
    const onSuccess = (name: string, tags: ContentTag[], message: string) => {
      // 編集時のデータで更新をかける
      setContentReport((current) => {
        if (!current) return current
        return {
          ...current,
          contents: current?.contents.map((content) => {
            if (content.id !== selectedId) return content
            return { ...content, name, tags }
          }),
        }
      })
      regist.onComplete()
      invalidateContentReport({ type: 'active' })
      resetContentReport({ type: 'inactive' })
      resetContentGroupReport()
      openToast({ message })
    }

    return (
      <SingleContentRegister
        projectId={projectId}
        selectedContent={selectedContent}
        canSave={props.canAddManagedContent}
        onClose={regist.onComplete}
        onSuccess={onSuccess}
      />
    )
  } else if (editingMultipleContents) {
    // 一括コンテンツ名登録
    const onSuccess = (response: UpdateMultipleContentResponse) => {
      // 編集時のデータで更新をかける
      setContentReport((current) => {
        if (!current) return current
        return {
          ...current,
          contents: current?.contents.map((content) => {
            if (!selectedContentIds.includes(content.id)) return content
            return {
              ...content,
              name: response.managed_contents.find((res) => res.partial_content_id === content.id)?.name || '',
            }
          }),
        }
      })
      onCompleteMultipleContent()
      invalidateContentReport({ type: 'active' })
      resetContentReport({ type: 'inactive' })
      resetContentGroupReport()
      openToast({ message: 'コンテンツ情報を追加しました' })
    }

    return (
      <MultipleContentRegister
        projectId={projectId}
        selectedContentIds={selectedContentIds}
        onClose={onCompleteMultipleContent}
        onSuccess={onSuccess}
      />
    )
  } else if (editingMultipleTags) {
    // 一括タグ登録
    const onSuccess = (tags: ContentTag[]) => {
      setContentReport((current) => {
        if (!current) return current
        return {
          ...current,
          contents: current?.contents.map((content) => {
            if (!selectedContentIds.includes(content.id)) return content
            return {
              ...content,
              tags: tags,
            }
          }),
        }
      })

      onCompleteMultipleTags()
      invalidateContentReport({ type: 'active' })
      resetContentReport({ type: 'inactive' })
      openToast({ message: 'タグを一括登録しました' })
    }

    return (
      <MultipleTagsRegister
        projectId={projectId}
        selectedContents={data.contents.filter((con) => selectedContentIds.includes(con.id))}
        onClose={onCompleteMultipleTags}
        onSuccess={onSuccess}
      />
    )
  } else {
    return null
  }
}
