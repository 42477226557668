import { useQuery } from '@tanstack/react-query'

import { TEAM_PV_QUERY_KEY } from './constants'
import { request } from '../../request'

export interface PageViewByYearMonthResponse {
  readonly year_month: string
  readonly count: number
}

export interface TeamPvResponse {
  readonly limit: number
  readonly page_views: Array<PageViewByYearMonthResponse>
}

export interface PageViewByYearMonth {
  readonly yearMonth: string
  readonly count: number
}

export interface TeamPv {
  readonly limit: number
  readonly items: Array<PageViewByYearMonth>
}

interface Props {
  readonly yearMonth?: string
}

export const useTeamPv = ({ yearMonth }: Props) => {
  const queryKey = [TEAM_PV_QUERY_KEY, { yearMonth }]

  return useQuery({
    queryKey: queryKey,
    queryFn: () => fetch(yearMonth),
    select: transform,
    staleTime: 1000 * 60 * 5,
  })
}

const fetch = async (yearMonth?: string) => {
  const path = yearMonth ? `/api/team/pv/?year_month=${yearMonth}` : '/api/team/pv/'
  return await request<TeamPvResponse>('GET', path, true)
}

const transform = (response: TeamPvResponse): TeamPv => {
  return {
    limit: response.limit,
    items: response.page_views.map((item) => {
      return {
        yearMonth: item.year_month,
        count: item.count,
      }
    }),
  }
}
