import * as React from 'react'
import styled from 'styled-components'
import { current } from 'immer'
import { colors } from '../../../../styleConstants'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../../../../components/common/Modal'
import { CancelButton, NormalButton } from '../../../../components/common/Button'
import {
  FunnelStepData,
  FunnelStepEditData,
  StepType,
  StepUrlMatchType,
} from '../../../../util/hooks/api/FunnelReport/types'
import { validateBigQueryRegexPattern } from '../../../../util/RegexUtils'
import { INIT_STEP, STEP_TYPES, STEP_URL_MATCH_TYPES } from '../../../../util/hooks/api/FunnelReport/constants'
import { STEP_TYPE_OPTIONS, STEP_URL_MATCH_TYPE_OPTIONS } from '../../../../util/hooks/api/FunnelReport/constants'
import { Select } from '../../../../components/common/Select'
import { RadioButtonRow } from '../../../../components/common/RadioButton'
import { ReportContext } from '../../../../contexts/ReportProvider'
import { Input } from '../../../../components/common/Input'
import { deepCopy } from '../../../../util/copy'
import { InputErrorMessage } from '../../../../components/errors/InputErrorMessage'

const MAX_STEP_NAME_LENGTH = 30
const MAX_STEP_URL_LENGTH = 2000

interface Props {
  readonly opened: boolean
  readonly editingStep: FunnelStepData
  readonly isCreate: boolean
  readonly updateEditingStep: (updateFn: (draft: FunnelStepEditData) => void) => void
  readonly onApply?: (newEditingStep: FunnelStepEditData) => void
  readonly onCancel: () => void
}

export function EditStepModal({ opened, editingStep, isCreate, updateEditingStep, onApply, onCancel }: Props) {
  const {
    state: { goalOptions },
  } = React.useContext(ReportContext)

  if (!goalOptions.length) return null

  const originalStep = React.useMemo(() => editingStep, [])

  const [stepUrlErrorMessages, setStepUrlErrorMessages] = React.useState<string[]>([])

  const validateStepUrl = () => {
    updateEditingStep((draft) => {
      const errorMessages = []

      const isExceedMaxLength = (draft.stepUrl?.url.length || 0) > MAX_STEP_URL_LENGTH
      if (isExceedMaxLength) {
        errorMessages.push(`この項目が${MAX_STEP_URL_LENGTH}文字より長くならないようにしてください。`)
      }

      const regexErrorMessage = (() => {
        const isUrlMatchTypeRegex = draft.stepUrl?.matchType === STEP_URL_MATCH_TYPES.REGEXP
        return isUrlMatchTypeRegex ? validateBigQueryRegexPattern(draft.stepUrl?.url || '') : ''
      })()
      if (regexErrorMessage) errorMessages.push(regexErrorMessage)

      setStepUrlErrorMessages(errorMessages)
    })
  }

  const disabled: boolean = (() => {
    if (!editingStep.name) return true

    const isTypeUrl = editingStep.stepType === STEP_TYPES.URL
    if (isTypeUrl) {
      const isUrlEmpty = !editingStep.stepUrl?.url
      const isUrlError = !!stepUrlErrorMessages.length || isUrlEmpty
      if (isUrlError) return true
    } else {
      const isGoalEmpty = !editingStep.stepGoal?.goalId
      if (isGoalEmpty) return true
    }

    // NOTE: JSON.stringifyを使ったobject直接比較はできなかった。stepType変更時にstepUrlやstepGoalをnullに書き換えることがあるため
    const isNotChanged = (() => {
      if (editingStep.name !== originalStep.name) return false
      if (editingStep.stepType !== originalStep.stepType) return false
      if (isTypeUrl) {
        if (editingStep.stepUrl?.url !== originalStep.stepUrl?.url) return false
        if (editingStep.stepUrl?.matchType !== originalStep.stepUrl?.matchType) return false
      } else {
        if (editingStep.stepGoal?.goalId !== originalStep.stepGoal?.goalId) return false
      }
      return true
    })()
    return isNotChanged
  })()

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditingStep((draft) => {
      draft.name = e.target.value
    })
  }

  const handleChangeStepType = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditingStep((draft) => {
      const stepType = parseInt(e.target.value) as StepType
      draft.stepType = stepType
      if (stepType === STEP_TYPES.URL) {
        draft.stepUrl = draft.stepUrl ?? INIT_STEP.stepUrl
      } else {
        draft.stepGoal = draft.stepGoal ?? {
          goalId: goalOptions[0].value as number,
          goalName: goalOptions[0].label,
        }
      }
    })
  }

  const handleChangeUrlMatchType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateEditingStep((draft) => {
      const url = draft.stepUrl?.url || ''
      const matchType = parseInt(e.target.value) as StepUrlMatchType
      draft.stepUrl = { url, matchType }
    })
    validateStepUrl()
  }

  const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditingStep((draft) => {
      const url = e.target.value
      const matchType = draft.stepUrl?.matchType || INIT_STEP.stepUrl?.matchType!
      draft.stepUrl = { url, matchType }
    })
  }

  const handleChangeGoalId = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateEditingStep((draft) => {
      const goalId = parseInt(e.target.value)
      draft.stepGoal = {
        goalId,
        goalName: goalOptions.find((option) => option.value === goalId)?.label || '',
      }
    })
  }

  const handleApply = () => {
    updateEditingStep((draft) => {
      // stepTypeで選択されていない方は不要なのでリセット
      if (draft.stepType === STEP_TYPES.URL) {
        draft.stepGoal = null
      } else {
        draft.stepUrl = null
      }

      // アクセス系の数字をハイフンにするためリセット
      draft.accessCount = null
      draft.transitionCount = null
      draft.transitionRate = null

      // NOTE: 更新サイクルが同じonApply実行時にはeditingStepが古いままだったので、最新のeditingStepを引数で渡すようにした
      const newEditingStep = current(draft)
      onApply && onApply(newEditingStep)
    })
  }

  // ゴールステップ保存後にゴールが削除された場合は空の選択肢を初期選択とする
  const newGoalOptions = React.useMemo(() => {
    if (editingStep.stepGoal === null || editingStep.stepGoal.goalId) return goalOptions

    const newOptions = deepCopy(goalOptions)
    newOptions.unshift({ label: '', value: '' })

    return newOptions
  }, [goalOptions])

  return (
    <Modal isOpen={opened} onClose={onCancel} title={isCreate ? 'ステップの追加' : 'ステップの編集'}>
      <ModalContentWrapper>
        <InputItem>
          <Label htmlFor="step-name">ステップ名</Label>
          <DesignedInput
            type="text"
            value={editingStep.name}
            onChange={handleChangeName}
            maxLength={MAX_STEP_NAME_LENGTH}
            required
            id="step-name"
            autoFocus={true}
          />
        </InputItem>

        <InputItem>
          <Label>ステップタイプ</Label>
          <RadioButtonWrapper>
            {STEP_TYPE_OPTIONS.map((option) => (
              <RadioButtonRow
                key={option.value}
                value={option.value}
                checked={editingStep.stepType === option.value}
                message={option.label}
                onChange={handleChangeStepType}
                color={`${colors.gray700}`}
                size={16}
              />
            ))}
          </RadioButtonWrapper>
        </InputItem>

        {editingStep.stepType === STEP_TYPES.URL && (
          <InputItem>
            <Label htmlFor="step-url">ステップURL</Label>
            <UrlDivide>
              <UrlTypeSelect
                value={editingStep.stepUrl?.matchType}
                options={STEP_URL_MATCH_TYPE_OPTIONS}
                onChange={handleChangeUrlMatchType}
                id="step-url"
              />
              <UrlInputArea>
                <UrlInput
                  type="text"
                  value={editingStep.stepUrl?.url}
                  onChange={handleChangeUrl}
                  onBlur={validateStepUrl}
                  required
                  look={!!stepUrlErrorMessages.length ? 'red' : undefined}
                  placeholder={'URLを入力'}
                />
                {!!stepUrlErrorMessages.length && (
                  <ErrorMessages>
                    {stepUrlErrorMessages.map((message, index) => (
                      <InputErrorMessage key={index}>{message}</InputErrorMessage>
                    ))}
                  </ErrorMessages>
                )}
              </UrlInputArea>
            </UrlDivide>
          </InputItem>
        )}

        {editingStep.stepType === STEP_TYPES.GOAL && (
          <InputItem>
            <Label htmlFor="step-goal">ゴール</Label>
            <DesignedSelect
              value={editingStep.stepGoal?.goalId}
              options={newGoalOptions}
              onChange={handleChangeGoalId}
              error={!editingStep.stepGoal?.goalId}
              id="step-goal"
            />
          </InputItem>
        )}
      </ModalContentWrapper>

      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <NormalButton disabled={disabled} onClick={handleApply}>
          {isCreate ? '追加' : '変更'}
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const InputItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 2px solid ${colors.white};
  & + & {
    margin-top: 24px;
  }
`

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 8px;
`

const DesignedInput = styled(Input)`
  font-size: 16px;
`

const DesignedSelect = styled(Select)`
  padding-right: 10px;
  font-size: 16px;
  height: 40px;
`

const RadioButtonWrapper = styled.div`
  display: flex;
  gap: 24px;
`

const UrlDivide = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
`

const UrlTypeSelect = styled(DesignedSelect)`
  width: 156px;
`

const UrlInputArea = styled.div`
  width: 100%;
`

const UrlInput = styled(DesignedInput)`
  width: 100%;
  height: 40px;
`

const ErrorMessages = styled.div`
  margin: 7px 0 0;
`
