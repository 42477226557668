import * as React from 'react'
import styled from 'styled-components'
import { Warning } from '@styled-icons/icomoon/Warning'

import { colors } from '../../../styleConstants'

interface Props {
  readonly size?: number
  readonly color?: string
}

export function WarningIcon({ size = 40, color = colors.orange }: Props) {
  return <StyledWarning size={size} color={color} />
}

export const StyledWarning = styled(Warning).attrs((props: Props) => ({
  size: props.size,
  color: props.color,
}))``
