"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/** @jsx jsx */
var React = __importStar(require("react"));
var react_1 = require("@emotion/react");
var helpers_1 = require("./helpers");
var rotate = react_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  100% { transform: rotate(360deg) }\n"], ["\n  100% { transform: rotate(360deg) }\n"])));
var Loader = /** @class */ (function (_super) {
    __extends(Loader, _super);
    function Loader() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.wrapper = function () {
            var _a = _this.props, size = _a.size, color = _a.color, speedMultiplier = _a.speedMultiplier;
            var _b = helpers_1.parseLengthAndUnit(size), value = _b.value, unit = _b.unit;
            return react_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      position: relative;\n      width: ", ";\n      height: ", ";\n      background-color: transparent;\n      box-shadow: inset 0px 0px 0px 2px ", ";\n      border-radius: 50%;\n\n      &:after,\n      &:before {\n        position: absolute;\n        content: \"\";\n        background-color: ", ";\n      }\n\n      &:after {\n        width: ", "px;\n        height: 2px;\n        top: ", "px;\n        left: ", "px;\n        transform-origin: 1px 1px;\n        animation: ", " ", "s linear infinite;\n      }\n\n      &:before {\n        width: ", "px;\n        height: 2px;\n        top: ", "px;\n        left: ", "px;\n        transform-origin: 1px 1px;\n        animation: ", " ", "s linear infinite;\n      }\n    "], ["\n      position: relative;\n      width: ", ";\n      height: ", ";\n      background-color: transparent;\n      box-shadow: inset 0px 0px 0px 2px ", ";\n      border-radius: 50%;\n\n      &:after,\n      &:before {\n        position: absolute;\n        content: \"\";\n        background-color: ", ";\n      }\n\n      &:after {\n        width: ", "px;\n        height: 2px;\n        top: ", "px;\n        left: ", "px;\n        transform-origin: 1px 1px;\n        animation: ", " ", "s linear infinite;\n      }\n\n      &:before {\n        width: ", "px;\n        height: 2px;\n        top: ", "px;\n        left: ", "px;\n        transform-origin: 1px 1px;\n        animation: ", " ", "s linear infinite;\n      }\n    "])), "" + value + unit, "" + value + unit, color, color, value / 2.4, value / 2 - 1, value / 2 - 1, rotate, 2 / speedMultiplier, value / 3, value / 2 - 1, value / 2 - 1, rotate, 8 / speedMultiplier);
        };
        return _this;
    }
    Loader.prototype.render = function () {
        var _a = this.props, loading = _a.loading, css = _a.css;
        return loading ? react_1.jsx("span", { css: [this.wrapper(), css] }) : null;
    };
    Loader.defaultProps = helpers_1.sizeDefaults(50);
    return Loader;
}(React.PureComponent));
exports.default = Loader;
var templateObject_1, templateObject_2;
