import { getBaseURL } from '../env'

function getBaseUrlWithPath(path: string) {
  const baseUrl = getBaseURL()
  if (!baseUrl) return path
  return new URL(path, baseUrl).toString()
}

type HTTPMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
export type ErrorType = string | Error

export async function request<T = unknown>(
  method: HTTPMethod,
  path: string,
  auth: boolean,
  body?: BodyInit | null,
): Promise<T> {
  const token = window.localStorage.getItem('token')
  // スーパーログインのシステムユーザー(スタッフ)を特定するトークン
  const syslogToken = window.localStorage.getItem('syslogToken')

  // formData を送信時はContent-Typeを空する必要があったため修正
  // @see https://github.com/uncovertruth/content-analytics/issues/1985
  const headers: HeadersInit =
    body instanceof FormData
      ? {}
      : {
          'Content-Type': 'application/json',
        }
  if (token && auth) {
    headers['Authorization'] = `Token ${token}`
  }
  if (syslogToken && auth) {
    headers['X-Ca-Syslog-Token'] = syslogToken
  }
  // 監査ログに記録する画面URL
  headers['X-Ca-Location'] = location.href

  const init: RequestInit = {
    method,
    body,
    cache: 'no-store',
    headers,
  }

  const url = getBaseUrlWithPath(path)
  const response = await fetch(url, init)

  if (response.status === 503) {
    // メンテナンスページへリダイレクトする
    location.href = '/maintenance'
    throw new Error(`${response.status} ${response.statusText}`)
  } else if (response.status === 401) {
    // セッション切れからのログインページへのリダイレクト処理
    let path: string = window.location.pathname
    // URLパラメータがあれば引き継ぐ
    if (window.location.search !== '') {
      path += window.location.search
    }
    // 組織管理、ログアウトからの遷移
    if (path.indexOf('/login') !== -1 || path.indexOf('/admin') !== -1 || path.indexOf('/logout') !== -1) {
      path = ''
    }
    // ログインページへリダイレクトする
    window.location.href = path ? `/login/?next=${path}` : '/login'
  }
  if (!response.ok) {
    const json: { messages: string[] } = await response.json()
    // エラーメッセージが返ってきた場合はそちらを使用
    if (json.messages?.length) {
      throw json.messages.join('')
    } else {
      throw new Error(`Fetch failed with ${response.status} ${response.statusText}`)
    }
  }
  try {
    const json = await response.json()
    if (json.auth_token) {
      window.localStorage.setItem('token', json.auth_token)
    }
    return json
  } catch (error) {
    //TODO: 恒久的には型キャストは削除する https://github.com/uncovertruth/content-analytics/issues/1810
    return response as unknown as T
  }
}
