import getWindow from './getWindow';
/**
 * Gets the number of pixels that an element's content is scrolled vertically.
 * @param node The DOM element
 */

function scrollTop(node, val) {
  var win = getWindow(node);
  var top = node.scrollTop;
  var left = 0;

  if (win) {
    top = win.pageYOffset;
    left = win.pageXOffset;
  }

  if (val !== undefined) {
    if (win) {
      win.scrollTo(left, val);
    } else {
      node.scrollTop = val;
    }
  }

  return top;
}

export default scrollTop;