"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _Calendar = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/Calendar"));

// Generated by script, don't edit it please.
var Calendar = (0, _createSvgIcon["default"])({
  as: _Calendar["default"],
  ariaLabel: 'calendar',
  category: 'legacy',
  displayName: 'Calendar'
});
var _default = Calendar;
exports["default"] = _default;
module.exports = exports.default;