import * as React from 'react'
import styled from 'styled-components'
import { AccordionBox } from '../../../common/AccordionBox'
import { BarChartAlt } from '@styled-icons/boxicons-regular'
import { GRID_MIN_WIDTH } from '../..'
import { QuadrantChart, QuadrantChartItem } from './QuadrantChart'
import { colors } from '../../../../styleConstants'
import CHART_EXPLAIN_IMAGE from '../../../../../images/content-report-quadrantchart.png'
import { useQuadrantChart } from '../../../../util/hooks/cookie/useQuadrantChart'

interface ChartPanelProps {
  readonly items: QuadrantChartItem[]
  readonly contentsSummaryViewRate: number
  readonly pageSummaryConversionsRate: number
  readonly handleChartItemsSelect: (ids: number[]) => void
}

interface BaseChartPanelProps {
  readonly informationComponent?: React.ReactNode
  readonly children: React.ReactNode
}

const FEATURE_NAME = '4象限マトリクス(α版)'

export function ChartPanel({
  items,
  contentsSummaryViewRate,
  pageSummaryConversionsRate,
  handleChartItemsSelect,
}: ChartPanelProps) {
  const chartDisabled = items.length === 0

  return (
    <BaseChartPanel
      informationComponent={
        chartDisabled && (
          <NoItemInfoText>
            コンテンツ名が登録されたアイテムがありません。
            <br />
            コンテンツ名を登録すると{FEATURE_NAME}の表示が可能になります。
          </NoItemInfoText>
        )
      }
    >
      <QuadrantChart
        items={items}
        contentsSummaryViewRate={contentsSummaryViewRate}
        pageSummaryConversionsRate={pageSummaryConversionsRate}
        handleChartItemsSelect={handleChartItemsSelect}
      />
    </BaseChartPanel>
  )
}

function BaseChartPanel({ informationComponent, children }: BaseChartPanelProps) {
  const { chartOpened, setChartOpened } = useQuadrantChart()
  const handleAccordionChange = (_event: React.SyntheticEvent, expanded: boolean) => {
    setChartOpened(expanded)
  }

  const chartDisabled = !!informationComponent

  return (
    <AccordionBoxes>
      <StyledAccordionBox
        title={`${FEATURE_NAME}を表示`}
        icon={<BarChartAlt />}
        accordionProps={{
          expanded: chartOpened,
          onChange: handleAccordionChange,
          sx: {
            // Accordion Header Style
            '.MuiButtonBase-root': {
              height: '32px',
            },
          },
        }}
        contentPadding={0}
      >
        <DisabledOverlayArea disabled={chartDisabled}>
          <CommentaryBgBox>
            <WidthLimitBox>
              <ChartBox>
                {children}
                {informationComponent}
              </ChartBox>
            </WidthLimitBox>
          </CommentaryBgBox>
        </DisabledOverlayArea>
      </StyledAccordionBox>
    </AccordionBoxes>
  )
}

export function UnavailableChartPanel() {
  return (
    <BaseChartPanel
      informationComponent={
        <UnavailableInfoText>
          コンテンツ名単位レポートで表示が可能です。
          <br />
          表示にはコンテンツ名登録が必要です。
        </UnavailableInfoText>
      }
    >
      <QuadrantChart items={[]} contentsSummaryViewRate={0} pageSummaryConversionsRate={0} />
    </BaseChartPanel>
  )
}

const AccordionBoxes = styled.div`
  min-width: ${GRID_MIN_WIDTH}px;
  margin: 0 0 19px;
`

const StyledAccordionBox = styled(AccordionBox)`
  padding: 0;
`

const CommentaryBgBox = styled.div`
  background: url(${CHART_EXPLAIN_IMAGE}) no-repeat;
  background-position-x: ${GRID_MIN_WIDTH}px;
  background-position-y: 15px;
  background-size: 432px;
`

const WidthLimitBox = styled.div`
  width: ${GRID_MIN_WIDTH}px;
`

const ChartBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 10px;
`

const NoItemInfoText = styled.div`
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 290px);
  background: #ffffffdd;
  border: 8px solid #ff000099;
  padding: 50px;
  font-size: 16px;
  line-height: 1.6;
  text-align: center;
`

const UnavailableInfoText = styled(NoItemInfoText)`
  left: calc(50% - 210px);
`

const DisabledOverlayArea = styled.div<{ disabled: boolean }>`
  position: relative;
  &:after {
    visibility: ${({ disabled }) => (disabled ? 'visible' : 'hidden')};
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
    opacity: 0.6;
  }
`
