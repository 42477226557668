import * as React from 'react'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { CancelButton, NormalButton } from '../../common/Button'
import { WarningBox } from '../../common/WarningBox'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../../common/Modal'
import { compareAndSortArrays } from '../../../util/ArrayUtils'
import { Content, ContentTag } from '../../../util/hooks/api/useContentReport'
import { useUpdateMultipleContentsTags } from '../../../util/hooks/api/useUpdateMultipleContentsTags'
import { TagNamesArea } from './TagNamesArea'
import { filterValidTagNames, getInitialTagNames } from './SingleContentRegister'

interface State {
  readonly projectId: string
  readonly selectedContents: Content[]
  readonly onClose: () => void
  readonly onSuccess: (tags: ContentTag[]) => void
}

export function MultipleTagsRegister({ projectId, selectedContents, onClose, onSuccess }: State) {
  const [errorMessage, setErrorMessage] = React.useState('')

  const { initialTagNames, isAllTagsMatched } = useMemo(() => getInitialTagNames(selectedContents), [selectedContents])
  const [tagNames, setTagNames] = useState(initialTagNames)

  const { mutate, isLoading } = useUpdateMultipleContentsTags({
    projectId,
    options: {
      onSuccess,
      onError: (e: string) => {
        setErrorMessage(typeof e === 'string' ? e : 'コンテンツタグの保存に失敗しました。')
      },
    },
  })

  const handleSave = async () => {
    const validTags = filterValidTagNames(tagNames)
    mutate({
      contentIds: selectedContents.map((content) => content.id),
      tagNames: validTags,
    })
  }

  return (
    <Modal isOpen={true} onClose={onClose} title="タグ一括登録" isLoading={isLoading}>
      <ModalContentWrapper>
        <Container>
          {errorMessage && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}
          <ContentText>
            選択中のコンテンツ
            <SelectedContentCount>{selectedContents.length}</SelectedContentCount>個
          </ContentText>
          <ContentText>コンテンツレポート、キャプチャ押下で選択したコンテンツを外すことも可能です。</ContentText>
          {!isAllTagsMatched && (
            <WarningBoxWrapper>
              <WarningBox>
                <WarningText>選択中のコンテンツには異なるタグがついているため、保存すると上書きされます。</WarningText>
              </WarningBox>
            </WarningBoxWrapper>
          )}
          {filterValidTagNames(initialTagNames).length > 0 && isAllTagsMatched && selectedContents.length > 1 && (
            <ContentText>選択中のコンテンツ全てに同一のタグがついているため、編集・追加が可能です。</ContentText>
          )}
          <TagNamesArea tagNames={tagNames} setTagNames={setTagNames} />
        </Container>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton
          disabled={
            // 空は除いて非活性チェックする
            compareAndSortArrays(filterValidTagNames(initialTagNames), filterValidTagNames(tagNames))
          }
          onClick={handleSave}
        >
          保存
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const Container = styled.div`
  font-size: 16px;
  max-height: 610px;
  overflow-y: auto;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
  margin: 0 0 14px 0;
`

const ContentText = styled.div`
  margin-bottom: 14px;
`

const SelectedContentCount = styled.span`
  color: ${colors.orange};
  font-size: 24px;
  font-weight: bold;
`

const WarningBoxWrapper = styled.div`
  margin-bottom: 24px;
`

const WarningText = styled.div`
  font-size: 14px;
`
