import {
  FunnelReportResponse,
  FunnelReportData,
  FunnelReportPayloadData,
  FunnelReportRequestBody,
  FunnelFilterResponse,
} from './types'
import { CustomFilterState, DimensionFilterState } from '../Filter/types'
import { AGGREGATE_CONDITIONS } from '../Filter/constants'
import { makeReportFilters } from '../../../makeReportFilters'
import { transformDimensionOption } from '../transformers/transformDimensionOption'
import { getFilterDimensionPageTargetColumn } from '../transformers/getFilterDimensionPageTargetColumn'
import { Percent } from '../../../Parse'
import { getScaledPercent } from '../../../getScaledPercent'

export const transformResponseToData = (response: FunnelReportResponse): FunnelReportData => {
  const results = response.funnels.map((funnel) => {
    const minAccessCount: number = 0 // NOTE: ファネルレポートは0を最小値としてデータバーを描く。ページレポートのようにMath.minで求めると最小値のバーが小さくなりすぎるため
    const maxAccessCount: number = Math.max(...funnel.steps.map((step) => step.access_count))
    return {
      name: funnel.name,
      stepRequired: funnel.step_required,
      filters: transformFilters(funnel.filters),
      steps: funnel.steps.map((step) => {
        return {
          name: step.name,
          stepType: step.step_type,
          stepUrl: step.step_url
            ? {
                matchType: step.step_url.match_type,
                url: step.step_url.url,
              }
            : null,
          stepGoal: step.step_goal
            ? {
                goalId: step.step_goal.goal_id,
                goalName: step.step_goal.goal?.name,
              }
            : null,
          accessCount: step.access_count,
          accessCountScaledPercent:
            typeof step.access_count === 'number'
              ? getScaledPercent(step.access_count, minAccessCount, maxAccessCount)
              : null,

          transitionCount: step.transition_count,
          transitionRate: typeof step.transition_rate === 'number' ? Percent.parse(step.transition_rate) : null,
        }
      }),
      errors: funnel.errors,
      hasFilterError: !!funnel.errors?.some((message) => message.indexOf('フィルタ') !== -1),
    }
  })

  return {
    name: response.name,
    funnels: results,
  }
}

const transformFilters = (filtersResponse: FunnelFilterResponse[]): CustomFilterState[] => {
  return filtersResponse.map((filter) => {
    return {
      aggregate: {
        unit: filter.scope,
        condition: filter.included ? AGGREGATE_CONDITIONS.INCLUDE : AGGREGATE_CONDITIONS.EXCLUDE,
      },
      dimensions: filter.conditions
        .map((cond) => {
          const dimensionOption = transformDimensionOption(
            cond.name,
            cond.custom_dimension,
            cond.goal,
            cond.data_import_field,
          )
          if (!dimensionOption) return
          const filterState = {
            condition: cond.match_type,
            value: cond.value,
            fromValue: cond.from_value,
            toValue: cond.to_value,
            goalId: cond.goal?.id,
            customDimensionId: cond.custom_dimension?.id,
            dataImportFieldId: cond.data_import_field?.id,
            target: getFilterDimensionPageTargetColumn(cond.name),
            ...dimensionOption,
          }
          return { states: [filterState] }
        })
        .filter(Boolean) as { states: DimensionFilterState[] }[],
    }
  })
}

export const makeRequestBodyFromPayload = (data: FunnelReportPayloadData): FunnelReportRequestBody => {
  return {
    name: data.name,
    funnels: data.funnels.map((funnel) => {
      return {
        name: funnel.name,
        step_required: funnel.stepRequired,
        filters: makeReportFilters(funnel.filters),
        steps: funnel.steps.map((step) => {
          return {
            name: step.name,
            step_type: step.stepType,
            step_url: step.stepUrl
              ? {
                  match_type: step.stepUrl.matchType,
                  url: step.stepUrl.url,
                }
              : null,
            step_goal: step.stepGoal
              ? {
                  goal_id: step.stepGoal.goalId,
                }
              : null,
          }
        }),
      }
    }),
  }
}

export const getMaxStepHeights = (
  maxStepLength: number,
  stepTitleRefs: React.MutableRefObject<(HTMLDivElement | null)[][]>,
) => {
  return Array.from({ length: maxStepLength }, (_, index) => {
    const maxStepHeight = Math.max(
      ...stepTitleRefs.current.map((refArray) => {
        const element = refArray[index]
        if (!element) return 0
        const originalHeight = element.style.height
        element.style.height = 'auto' // NOTE: elementにセットされたheightを削除、offsetHeightを取得、元のheightを復元、の手順がすべて必要だった
        const offsetHeight = element.offsetHeight || 0
        element.style.height = originalHeight
        return offsetHeight
      }),
    )
    return maxStepHeight
  })
}
