"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

exports.__esModule = true;
exports["default"] = void 0;

var _createSvgIcon = _interopRequireDefault(require("../../createSvgIcon"));

var _AngleUp = _interopRequireDefault(require("@rsuite/icon-font/lib/legacy/AngleUp"));

// Generated by script, don't edit it please.
var AngleUp = (0, _createSvgIcon["default"])({
  as: _AngleUp["default"],
  ariaLabel: 'angle up',
  category: 'legacy',
  displayName: 'AngleUp'
});
var _default = AngleUp;
exports["default"] = _default;
module.exports = exports.default;