import React from 'react'
import styled from 'styled-components'
import { FunnelReportData } from '../../../util/hooks/api/FunnelReport/types'
import { Steps } from './Steps'
import { FunnelStepRequired } from '../EditReport/FunnelStepRequired'
import { colors } from '../../../styleConstants'
import { InputErrorMessage } from '../../../components/errors/InputErrorMessage'
import { getMaxStepHeights } from '../../../util/hooks/api/FunnelReport/utils'
import { FunnelAppliedFilterLabel } from '../../../components/FunnelAppliedFilterLabel'

interface Props {
  data: FunnelReportData
}

export function Funnels({ data }: Props) {
  const stepTitleRefs = React.useRef<(HTMLDivElement | null)[][]>([])
  const [maxHeights, setMaxHeights] = React.useState<number[]>([])

  const funnelNameRefs = React.useRef<(HTMLDivElement | null)[]>([])
  const [maxFunnelNameHeight, setMaxFunnelNameHeight] = React.useState<string>('auto')

  React.useEffect(() => {
    if (stepTitleRefs.current.length) {
      const maxStepLength = Math.max(...stepTitleRefs.current.map((refArray) => refArray.length))
      const maxStepHeights = getMaxStepHeights(maxStepLength, stepTitleRefs)
      setMaxHeights(maxStepHeights)
    }
  }, [data])

  // ステップの高さを合わせてレポートを見やすくするため、全ファネルのファネル名要素でMAXのheightを取得する
  React.useEffect(() => {
    if (!funnelNameRefs.current) return

    const heights = funnelNameRefs.current.map((ref) => (ref ? ref.offsetHeight : 0))
    const max = Math.max(...heights)
    setMaxFunnelNameHeight(`${max}px`)
  }, [funnelNameRefs.current])

  return (
    <FunnelsContainer>
      {data.funnels.map((funnel, funnelIndex) => {
        const hasError = !!funnel.errors && funnel.errors.length > 0

        return (
          <FunnelContainer key={funnelIndex} hasError={hasError}>
            <FunnelName
              heightValue={maxFunnelNameHeight}
              ref={(ref) => {
                funnelNameRefs.current[funnelIndex] = ref
              }}
            >
              {funnel.name || '-'}
            </FunnelName>
            {hasError && (
              <InputErrorMessages>
                {funnel.errors.map((message, index) => (
                  <InputErrorMessage key={index}>{message}</InputErrorMessage>
                ))}
              </InputErrorMessages>
            )}
            <FunnelAppliedFilterLabel filterLength={funnel.filters.length} hasFilterError={funnel.hasFilterError} />
            <Border />
            <FunnelStepRequired checked={funnel.stepRequired} />
            <Steps
              steps={funnel.steps}
              setRefs={(refArray) => (stepTitleRefs.current[funnelIndex] = refArray)}
              maxHeights={maxHeights}
            />
          </FunnelContainer>
        )
      })}
    </FunnelsContainer>
  )
}

export const FunnelsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  color: #121212;
`

export const FunnelContainer = styled.div<{ hasError?: boolean }>`
  width: 272px;
  min-width: 272px;
  padding: 16px;
  background: ${colors.lightCyan};
  ${({ hasError }) =>
    hasError &&
    `
    background: ${colors.gray100};
    border: 1px solid ${colors.error};
  `}
`

const FunnelName = styled.div<{ heightValue: string }>`
  height: ${({ heightValue }) => heightValue};
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
`

export const InputErrorMessages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 16px 0;
`

const Border = styled.div`
  margin-bottom: 9px;
  border-bottom: 1px solid ${colors.blue};
`
