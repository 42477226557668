import React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { IconButton } from '../common/Button'
import { FilterAlt } from '@styled-icons/boxicons-regular'

interface Props {
  filterLength: number
  hasFilterError?: boolean
  onClick: () => void
}
export function FunnelFilterEditButton({ filterLength, hasFilterError, onClick }: Props) {
  return (
    <DesignedIconButton
      apply={!!filterLength}
      hasFilterError={hasFilterError}
      iconLeft={<FilterAlt size={15} />}
      onClick={onClick}
    >
      フィルタ{!!filterLength && `（${filterLength}）`}
    </DesignedIconButton>
  )
}

const DesignedIconButton = styled(IconButton)<{ apply: boolean; hasFilterError?: boolean }>`
  margin-bottom: 16px;
  min-width: 126px;
  flex-shrink: 0;

  ${({ apply }) =>
    apply
      ? `
        background-color: ${colors.contentOrange.orange6};
        &:hover {
          background-color: ${colors.contentOrange.orange5};
        }`
      : `
        background-color: ${colors.white};
        color: ${colors.contentBlue.blue7};
        border: 1px solid ${colors.contentBlue.blue7};
        &:hover {
          background-color: ${colors.contentBlue.blue6};
          color: ${colors.white};
        }`};

  ${({ hasFilterError }) =>
    hasFilterError &&
    `
      background-color: ${colors.white};
      color: ${colors.error};
      border: 1px solid ${colors.error};
      &:hover {
        background-color: ${colors.error};
        color: ${colors.white};
      }`}
`
