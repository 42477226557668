import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../../common/Modal'
import { CancelButton, NormalButton } from '../../common/Button'
import { Input } from '../../common/Input'
import { WarningBox } from '../../common/WarningBox'
import {
  UpdateMultipleContentResponse,
  useUpdateMultipleContent,
} from '../../../util/hooks/api/useUpdateMultipleContent'

interface State {
  readonly projectId: string
  readonly selectedContentIds: number[]
  readonly onClose: () => void
  readonly onSuccess: (response: UpdateMultipleContentResponse) => void
}

export function MultipleContentRegister({ projectId, selectedContentIds, onClose, onSuccess }: State) {
  const [contentName, setContentName] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')

  const { mutate, isLoading } = useUpdateMultipleContent({
    projectId,
    options: {
      onSuccess,
      onError: (e: string) => {
        setErrorMessage(typeof e === 'string' ? e : 'コンテンツ情報の編集に失敗しました。')
      },
    },
  })

  const onClickSaveButton = () => {
    mutate({
      name: contentName,
      partial_content_ids: selectedContentIds,
    })
  }

  return (
    <Modal isOpen={true} onClose={onClose} title="コンテンツ名一括登録" isLoading={isLoading}>
      <ModalContentWrapper>
        <Container>
          {errorMessage && <ErrorMessage data-testid="error-message">{errorMessage}</ErrorMessage>}
          <ContentText>
            選択中のコンテンツ <SelectedContentCount>{selectedContentIds.length}</SelectedContentCount>個
          </ContentText>
          <ContentText>コンテンツレポート、キャプチャ押下で選択したコンテンツを外すことも可能です。</ContentText>
          <WarningBoxWrapper>
            <WarningBox>
              <WarningText>既にコンテンツ名が登録されていた場合、上書きされます。</WarningText>
            </WarningBox>
          </WarningBoxWrapper>
          <Label>コンテンツ名</Label>
          <NameInputWrapper>
            <DesignedInput
              type="text"
              value={contentName}
              onChange={(e) => {
                setContentName(e.target.value)
              }}
              fontSize={16}
              autoFocus={true}
            />
          </NameInputWrapper>
        </Container>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton disabled={!contentName} onClick={onClickSaveButton}>
          保存
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

const Container = styled.div`
  font-size: 16px;
`

const Label = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: 40px;
  margin-bottom: 0.3rem;
`

const ErrorMessage = styled.p`
  color: ${colors.error};
  margin: 0 0 14px 0;
`

const ContentText = styled.div`
  margin-bottom: 14px;
`

const SelectedContentCount = styled.span`
  color: ${colors.orange};
  font-size: 24px;
  font-weight: bold;
`

const WarningBoxWrapper = styled.div`
  margin-bottom: 24px;
`
const NameInputWrapper = styled.div`
  margin-bottom: 28px;
`
const WarningText = styled.div`
  font-size: 14px;
`
