import { useQuery } from '@tanstack/react-query'
import { request } from '../../../request'
import { CustomFilterState } from './types'
import { makeReportFilters } from '../../../makeReportFilters'
import { FILTER_TEXT_TRANSFORM } from '../constants'

export type FilterTexts = {
  scopeLabel: string
  includedLabel: string
  conditionTexts: string[]
}[]

export type FilterTextTransformItemResponse = {
  scope_label: string
  included_label: string
  condition_texts: string[]
}

export type FilterTextTransformResponse = {
  results: FilterTextTransformItemResponse[]
}

export const useFilterTextTransform = ({ projectId, payload }: { projectId: number; payload: CustomFilterState[] }) => {
  const queryKey = [FILTER_TEXT_TRANSFORM, { projectId, payload }]

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const apiPayload = {
        filters: makeReportFilters(payload),
      }
      const response = await request<FilterTextTransformResponse>(
        'POST',
        `/api/projects/${projectId}/filter_text_transform/`,
        true,
        JSON.stringify(apiPayload),
      )
      return response.results
    },
    select: (filterTexts): FilterTexts => {
      return filterTexts.map((text) => {
        return {
          conditionTexts: text.condition_texts,
          includedLabel: text.included_label,
          scopeLabel: text.scope_label,
        }
      })
    },
    enabled: !!payload.length,
  })
  return {
    ...queryResult,
  }
}
