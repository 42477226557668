import * as React from 'react'

/**
 * useFormGuardDialog
 *
 * フォームが変更された状態（`isDirty`がtrueの場合）で、ユーザーがページを離れようとした際に
 * 確認ダイアログを表示して、ユーザーに確認を促すカスタムフック。
 * 表示条件は、ページ内のリンククリック(_blank属性付きを除く)、ブラウザのリロードボタン・閉じるボタンクリック時。
 *
 * FIXME: historybackは未対応。以下issueで対応予定
 * see. https://github.com/uncovertruth/content-analytics/issues/2627
 *
 * @param {boolean} isDirty - フォームが変更された状態かどうかを示すフラグ。
 *
 * @example
 * const isFormDirty = editingData !== originalData
 * useFormGuard(isFormDirty);
 *
 * @see reference {@link https://zenn.dev/nino/articles/fafa5053364c03|Next.js App Router でのフォーム離脱防止}
 */
export const useFormGuardDialog = (isDirty: boolean) => {
  React.useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (isDirty && event.target instanceof Element && event.target.closest('a:not([target="_blank"]')) {
        if (!window.confirm('編集中の内容は、まだ保存されていません。よろしいですか？')) {
          event.preventDefault()
          event.stopPropagation()
        }
      }
    }

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!isDirty) return
      event.preventDefault() // NOTE: preventDefaultメソッドを呼ぶことで一部ブラウザが確認ダイアログを表示する仕様
      return (event.returnValue = '') // NOTE: returnValue属性に文字列をセットすることで一部ブラウザが確認ダイアログを表示する仕様
    }

    window.addEventListener('click', handleClick, true)
    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('click', handleClick, true)
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty])
}
