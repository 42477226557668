import camelizeStyleName from './utils/camelizeStyleName';
import getComputedStyle from './utils/getComputedStyle';
import hyphenateStyleName from './utils/hyphenateStyleName';
/**
 * Gets the value for a style property
 * @param node  The DOM element
 * @param property  The style property
 * @returns The value of the style property
 */

export default function getStyle(node, property) {
  if (property) {
    var value = node.style[camelizeStyleName(property)];

    if (value) {
      return value;
    }

    var styles = getComputedStyle(node);

    if (styles) {
      return styles.getPropertyValue(hyphenateStyleName(property));
    }
  }

  return node.style || getComputedStyle(node);
}