import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../../../styleConstants'
import { Actions, ProjectSettingsType } from '../../../pages/Settings/state'
import { FileDownload } from '@styled-icons/remix-line/FileDownload'
import { Pageview } from '@styled-icons/material-outlined/Pageview'
import { BarChart } from '../../charts/BarChart'
import { Legends } from '../../charts/Legends'
import { useToast } from '../../../util/hooks/useToast'
import { IconButton } from '../../common/Button'
import { Select } from '../../common/Select'
import { Checkbox } from '../../common/Checkbox'
import { AccordionBox } from '../../common/AccordionBox'
import { useDownloadPvByUrl } from '../../../util/hooks/api/ProjectSettings/useDownloadPvByUrl'
import { Loading } from '../../common/Loading'
import { ErrorBox } from '../../common/ErrorBox'
import { downloadFile } from '../../../util/downloadUtils'

interface Props {
  projectId: string
  defaultExpanded: boolean
  project: ProjectSettingsType
  pageActions: Actions
}

export function PvCountPanel({ projectId, defaultExpanded, project, pageActions }: Props) {
  const { openToast } = useToast()

  const { mutate, isLoading, isError, error } = useDownloadPvByUrl({
    projectId,
    options: {
      onSuccess: (downloadUrl: string) => {
        downloadFile(downloadUrl)
        openToast({ message: `ダウンロードしました。` })
      },
    },
  })

  const handleDownload = () => {
    const pvYearMonth = project.selectedLabel
    if (!pvYearMonth) return
    mutate({ targetYearMonth: pvYearMonth })
  }

  return (
    <AccordionBox title="PV数" icon={<Pageview />} accordionProps={{ defaultExpanded }}>
      <ProjectContainer>
        <div style={{ margin: '0 0 20px' }}>
          PV数とは、タグを設置したURLに対してタグ設置時からカウントされるものです。
        </div>

        <Label>月間上限PV</Label>
        <div style={{ marginBottom: '1.5rem' }}>{`${project.pvLimit.toLocaleString()} PV`}</div>

        <Label>消費PV</Label>
        {project.projectPvOptions.length !== 0 ? (
          <div>
            <DesignedSelect options={project.projectPvOptions} onChange={pageActions.project.onPvChange} />
            <div style={{ display: 'flex' }}>
              <div>{`${project.projectPv.toLocaleString()} PV`}</div>
              <div
                style={{
                  color: `${colors.gray500}`,
                }}
              >{`（組織全体 ${project.pvCount.toLocaleString()} PV）`}</div>
            </div>
          </div>
        ) : (
          <div>{`消費PVはありません`}</div>
        )}

        {/* PVグラフ */}
        {project.projectPvInfo.length > 0 && <Label style={{ marginTop: '1.5rem' }}>プロジェクト別消費PV</Label>}
        {project.projectPvInfo.length > 0 && (
          <div style={{ width: `${layout.barChartWidth}` }}>
            <BarChart
              data={project.barData}
              keys={project.barKeys}
              enableLabel={true}
              layout="horizontal"
              maxValue={project.barMaxValue}
              width={`${layout.barChartWidth}`}
              height={70}
              margin={{ top: 0, right: 40, bottom: 30, left: 25 }}
              labelSkipWidth={40}
              legendsTranslateY={60}
              legends={false}
              itemWidth={pageActions.project.getProjectNameNum() * 7 + 20}
              colors={pageActions.project.setColor}
            />
            <Legends legends={project.legends} style={{ margin: '1rem 0' }} />
          </div>
        )}
        {project.projectPvInfo.length > 0 &&
          (project.checkDisabled ? (
            <div style={{ height: '25px' }} />
          ) : (
            <CheckboxContainer onClick={pageActions.project.onClickOptimize}>
              <DesignedCheckbox checked={project.checked} />
              <span style={{ marginLeft: '0.5rem' }}>グラフの上限値を月間上限PVに合わせる</span>
            </CheckboxContainer>
          ))}

        {isLoading && <Loading />}
        {isError && <ErrorBox>{typeof error === 'string' ? error : 'ダウンロードに失敗しました。'}</ErrorBox>}
        <ButtonContainer>
          <IconButton iconLeft={<FileDownload size={15} />} onClick={handleDownload}>
            CSVダウンロード
          </IconButton>
        </ButtonContainer>
      </ProjectContainer>
    </AccordionBox>
  )
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  width: 290px;
  height: 25px;
  cursor: pointer;
`

const DesignedCheckbox = styled(Checkbox)`
  width: 20px;
  height: 20px;
  margin: 0.5rem 0;
`

const DesignedSelect = styled(Select)`
  width: 90px;
  height: 30px;
  font-size: 0.9rem;
  padding: 0.2rem 0.5rem;
  margin-bottom: 0.5rem;
`

const ProjectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 0 1rem;
`

const Label = styled.div`
  font-weight: 600;
  margin-bottom: 0.5rem;
`
