import * as React from 'react'
import { request } from '../../util/request'
import { getDateStringYM } from '../../util/Date'
import { TeamData } from '../../util/Response'
import { State } from './state'
import { LegendsState } from '../../components/charts/Legends'

// プロジェクト別のPV情報
export interface ProjectPvInfo {
  readonly name: string
  readonly results: Array<{
    readonly yearMonth: string
    readonly count: number
  }>
}

export class Info {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly openToast: ({ message }: { message: string }) => void,
  ) {}

  // 組織名の編集
  updateName = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      info: {
        ...this.state.info,
        tempTeamName: event.currentTarget.value,
        disabled: !(event.currentTarget.validity.valid && this.state.info.teamName !== event.currentTarget.value),
      },
    })
  }

  // 組織名の変更リセット
  onCancel = () => {
    this.setState({
      ...this.state,
      info: {
        ...this.state.info,
        tempTeamName: this.state.info.teamName,
        disabled: true,
      },
    })
  }

  // 組織名の変更を送信
  onApply = async () => {
    try {
      const teamData: TeamData = await request(
        'PATCH',
        `/api/team/`,
        true,
        JSON.stringify({ name: this.state.info.tempTeamName }),
      )
      this.openToast({ message: '組織情報を変更しました' })
      this.setState({
        ...this.state,
        info: {
          ...this.state.info,
          teamName: teamData.name,
          tempTeamName: teamData.name,
          disabled: true,
        },
        teamErrorMessage: '',
        toastMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        teamErrorMessage: typeof e === 'string' ? e : '組織情報の変更に失敗しました。',
      })
    }
  }

  // PV表示切り替え
  onPvChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const label = e.target.options[e.target.selectedIndex].label
    const { data, keys, count, legends } = this.createBarData(this.state.info.projectPv, label)
    const disabled = !(getDateStringYM(new Date()) === label) // 当月以外は最適化のチェックボックスを操作できない
    this.setState({
      ...this.state,
      info: {
        ...this.state.info,
        barData: [data],
        barKeys: keys,
        pvTotal: count,
        pvCount: Number(e.target.value),
        barMaxValue: disabled ? count : this.state.info.checked ? this.state.info.pvLimit : count,
        checkDisabled: disabled,
        selectedLabel: label, // 選択しているラベルを保持
        legends: legends,
      },
    })
  }

  // グラフのX軸最適化を行うチェックボックス
  onClickOptimize = () => {
    const check = !this.state.info.checked
    const maxValue = check ? this.state.info.pvLimit : this.state.info.pvTotal
    this.setState({
      ...this.state,
      info: {
        ...this.state.info,
        checked: check,
        barMaxValue: maxValue,
      },
    })
  }

  // 文字列を数値に変換する
  stringToNumber = (str: string) => {
    // 文字列 -> 配列 -> 文字コードの配列 -> 全部足す
    return Array.from(str)
      .map((ch) => ch.charCodeAt(0))
      .reduce((a, b) => a + b)
  }

  // グラフデータ作成
  createBarData = (infos: ProjectPvInfo[], yearMonth: string) => {
    let data = { title: 'PV' }
    let keys: string[] = []
    let count = 0
    let legends: LegendsState[] = []
    infos.map((info) => {
      const counts = info.results.filter((result) => result.yearMonth === yearMonth)
      if (counts.length > 0) {
        const n = this.stringToNumber(info.name)
        const d = {
          [`${info.name}`]: counts[0].count,
          [`${info.name} Color`]: `hsl(${(n * n) % 360}, 80%, 64%)`,
        }
        const l: LegendsState = { name: info.name, color: `hsl(${(n * n) % 360}, 80%, 64%)` }
        data = { ...data, ...d }
        keys.push(info.name)
        count += counts[0].count
        legends.push(l)
      }
    })
    return { data, keys, count, legends }
  }

  // グラフのカラー設定関数
  setColor = (bar: any) => {
    const n = this.stringToNumber(bar.id)
    return `hsl(${(n * n) % 360}, 80%, 64%)`
  }

  // プロジェクトPVリストから最も長い名前の文字列数を取得
  getProjectNameNum = () => {
    let len = 0
    this.state.info.projectPv.forEach((info) => {
      if (info.name.length > len) {
        len = info.name.length
      }
    })
    return len
  }
}
