import * as React from 'react'
import { navigate } from '@gatsbyjs/reach-router'
import styled from 'styled-components'
import { useImmer } from 'use-immer'
import { UseMutateFunction } from '@tanstack/react-query'

import {
  EditingStepIndex,
  FunnelReportData,
  FunnelReportEditData,
  FunnelReportPayloadData,
  FunnelStepData,
} from '../../../util/hooks/api/FunnelReport/types'
import { CancelButton, NormalButton } from '../../../components/common/Button'
import { useToast } from '../../../util/hooks/useToast'
import { useFunnelReports } from '../../../util/hooks/api/FunnelReport/useFunnelReports'
import { ProjectContext } from '../../ProjectRoot'
import { EditStepModal } from './EditStepModal'
import { EditCustomFilterModal } from '../EditCustomFilterModal'
import { CustomFilterState } from '../../../util/hooks/api/Filter/types'
import {
  INIT_EDIT_DATA,
  INIT_STEP,
  MAX_FUNNEL_LENGTH,
  MAX_REPORT_NAME_LENGTH,
  MAX_STEP_LENGTH,
  REPORT_REGISTER_FOOTER_HEIGHT,
} from '../../../util/hooks/api/FunnelReport/constants'
import { Input } from '../../../components/common/Input'
import { colors, layout } from '../../../styleConstants'

import { Funnels } from './Funnels'
import { HelpLink } from '../../../components/common/HelpLink'
import { HELP_LINKS } from '../../../constants'
import { InputErrorMessage } from '../../../components/errors/InputErrorMessage'
import {
  checkFilterCustomDimensionError,
  checkFilterDataImportError,
  checkFilterGoalError,
  checkStepGoalError,
} from './utils'
import { useFormGuardDialog } from '../../../util/hooks/useFormGuard'

interface Props {
  data: FunnelReportData | undefined
  saveMutate: UseMutateFunction<number, unknown, FunnelReportPayloadData, unknown>
  onInvalidate: () => void
  onCancel: () => void
}

export function EditReport({ data, saveMutate, onInvalidate, onCancel }: Props) {
  const { openToast } = useToast()

  const {
    state: { projectId, baseUrl },
  } = React.useContext(ProjectContext)

  const { data: funnelReportsData } = useFunnelReports({ projectId })
  const reportNames = React.useMemo(() => {
    if (!funnelReportsData) return []
    const originalReportName = data?.name || ''
    return funnelReportsData.results.filter((report) => report.name !== originalReportName).map((report) => report.name)
  }, [funnelReportsData])

  const [editingData, updateEditingData] = useImmer<FunnelReportEditData>(data || INIT_EDIT_DATA)
  const originalData = React.useMemo(() => editingData, [])

  const [editingStep, updateEditingStep] = useImmer<FunnelStepData>(INIT_STEP)
  const [editingStepIndex, setEditingStepIndex] = React.useState<EditingStepIndex | null>(null)
  const [isStepModalOpened, setIsStepModalOpened] = React.useState(false)
  const [isFilterModalOpened, setIsFilterModalOpened] = React.useState(false)
  const [funnelIndexForEditingFilter, setFunnelIndexForEditingFilter] = React.useState<number | null>(null)
  const [editingFilter, setEditingFilter] = React.useState<CustomFilterState[] | null>(null)

  const [reportNameErrorMessages, setReportNameErrorMessages] = React.useState<string[]>([])

  const isFormDirty = editingData !== originalData // NOTE: 一度でも入力操作をした場合、内容が同一でもtrueにする

  const saveButtonDisabled: boolean =
    !isFormDirty ||
    !editingData.name ||
    !!reportNameErrorMessages.length ||
    editingData.funnels.some((funnel) => {
      return (
        checkStepGoalError(funnel.steps) ||
        checkFilterGoalError(funnel.filters) ||
        checkFilterCustomDimensionError(funnel.filters) ||
        checkFilterDataImportError(funnel.filters)
      )
    })

  useFormGuardDialog(isFormDirty)

  const handleUpdateReport = () => {
    saveMutate(editingData, {
      onSuccess: (id) => {
        openToast({ message: 'ファネルレポートを保存しました。' })
        onInvalidate()
        navigate(`${baseUrl}report/funnel/${id}/`)
      },
    })
  }

  const handleChangeReportName = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateEditingData((draft) => {
      draft.name = e.target.value
    })
  }

  const validateReportName = (e: React.FocusEvent<HTMLInputElement>) => {
    const errorMessages = []
    const existName = !!reportNames.find((reportName) => reportName === e.target.value)
    if (existName) {
      errorMessages.push('すでに登録済みのレポート名です。')
    }
    setReportNameErrorMessages(errorMessages)
  }

  const handleApplyFilter = (state: CustomFilterState[]) => {
    if (funnelIndexForEditingFilter === null) return
    updateEditingData((draft) => {
      draft.funnels[funnelIndexForEditingFilter].filters = state
    })
    resetAccessCount(funnelIndexForEditingFilter, 0)
    setFunnelIndexForEditingFilter(null)
    setEditingFilter(null)
  }

  const onSaveEditingStep = (newEditingStep: FunnelStepData) => {
    if (!editingStepIndex) return
    updateEditingData((draft) => {
      if (editingStepIndex.isInsertNewStep) {
        draft.funnels[editingStepIndex.funnelIndex].steps.splice(editingStepIndex.stepIndex + 1, 0, newEditingStep)
      } else {
        draft.funnels[editingStepIndex.funnelIndex].steps[editingStepIndex.stepIndex] = newEditingStep
      }
    })
    resetAccessCount(
      editingStepIndex.funnelIndex,
      editingStepIndex.stepIndex + (editingStepIndex.isInsertNewStep ? 1 : 0),
    )
    updateEditingStep(INIT_STEP)
    setEditingStepIndex(null)
    setIsStepModalOpened(false)
  }

  const onCancelEditingStep = () => {
    updateEditingStep(INIT_STEP)
    setEditingStepIndex(null)
    setIsStepModalOpened(false)
  }

  const resetAccessCount = (funnelIndex: number, stepIndex: number) => {
    updateEditingData((draft) => {
      draft.funnels[funnelIndex].steps = draft.funnels[funnelIndex].steps.map((step, index) => {
        if (index < stepIndex) {
          return step
        }
        return {
          ...step,
          accessCount: null,
          accessCountScaledPercent: null,
          transitionCount: null,
          transitionRate: null,
        }
      })
    })
  }

  return (
    <Container>
      {/* NOTE: EditStepModalのstateをリセットするためopenedを外出しした */}
      {isStepModalOpened && (
        <EditStepModal
          opened={true}
          editingStep={editingStep}
          isCreate={!!editingStepIndex?.isInsertNewStep}
          updateEditingStep={updateEditingStep}
          onApply={onSaveEditingStep}
          onCancel={onCancelEditingStep}
        />
      )}

      {isFilterModalOpened && (
        <EditCustomFilterModal
          filters={editingFilter}
          setFilters={handleApplyFilter}
          opened={isFilterModalOpened}
          setOpened={setIsFilterModalOpened}
        />
      )}

      <HeadArea>
        <HintSection>
          <HintSectionText>
            ファネルレポートに追加可能なファネル数の上限は{MAX_FUNNEL_LENGTH}
            つ、各ファネルに追加可能なステップ数の上限は{MAX_STEP_LENGTH}つまでです。
          </HintSectionText>
          <HelpLink title={'ファネルレポート'} link={HELP_LINKS.FUNNEL_REPORT} style={{ marginLeft: '0' }} />
        </HintSection>

        <ReportName>
          <label>
            <ReportNameTitle>ファネルレポート名</ReportNameTitle>
            <ReportNameInput
              value={editingData.name}
              onChange={handleChangeReportName}
              onBlur={validateReportName}
              required
              maxLength={MAX_REPORT_NAME_LENGTH}
              look={!!reportNameErrorMessages.length ? 'red' : undefined}
            />
          </label>
        </ReportName>

        {!!reportNameErrorMessages.length && (
          <ErrorMessages>
            {reportNameErrorMessages.map((message, index) => (
              <InputErrorMessage key={index}>{message}</InputErrorMessage>
            ))}
          </ErrorMessages>
        )}
      </HeadArea>

      <Funnels
        funnels={editingData.funnels}
        isExceedFunnel={MAX_FUNNEL_LENGTH <= editingData.funnels.length}
        updateEditingData={updateEditingData}
        setEditingStepIndex={setEditingStepIndex}
        setEditingStep={updateEditingStep}
        setIsStepModalOpened={setIsStepModalOpened}
        setIsFilterModalOpened={setIsFilterModalOpened}
        setFunnelIndexForEditingFilter={setFunnelIndexForEditingFilter}
        setEditingFilter={setEditingFilter}
        resetAccessCount={resetAccessCount}
      />

      <ReportRegisterFooter>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <NormalButton onClick={handleUpdateReport} disabled={saveButtonDisabled}>
          保存
        </NormalButton>
      </ReportRegisterFooter>
    </Container>
  )
}

const Container = styled.div`
  line-height: 1.5;
`

const HeadArea = styled.div`
  margin-bottom: 24px;
`

const HintSection = styled.div`
  margin-bottom: 24px;
  color: #666666;
`
const HintSectionText = styled.div`
  font-size: 16px;
`

const ReportName = styled.div`
  margin-bottom: 5px;
`

const ReportNameTitle = styled.div`
  margin-bottom: 4px;
  font-weight: bold;
`

const ReportNameInput = styled(Input)`
  width: 836px;
  height: 40px;
  font-size: 16px;
`

const ErrorMessages = styled.div`
  margin: 7px 0 0;
`

const ReportRegisterFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${REPORT_REGISTER_FOOTER_HEIGHT}px;
  gap: 16px;
  padding: 0 32px;
  background-color: ${colors.white};
  align-items: center;
  transition-duration: 0.3s;
  z-index: ${layout.funnelReportRegisterFooterZIndex};
`
