import * as React from 'react'
import styled from 'styled-components'
import { getColorByRateGrade, getFontWeightByRateGrade, RateGrade } from '../../../util/getConversionRateGrade'
import { LOWEST_RANK_TO_SHOW } from '..'
import { BaseCell } from './BaseCell'
import { RankLabel } from '../RankLabel'
import { DATA_BAR_COLORS } from '../../../styleConstants'

interface Props {
  readonly dataId: number
  readonly goalGrade: RateGrade
  readonly goalRate: number
  readonly goalRateRank: number
  readonly barPercent: number
}

export function GoalRateCell({ dataId, goalRate, goalGrade, goalRateRank, barPercent }: Props) {
  const rankVisible = goalRate !== 0 && goalRateRank <= LOWEST_RANK_TO_SHOW
  return (
    <Cell data-id={dataId} rankVisible={rankVisible} barPercent={barPercent} barColor={DATA_BAR_COLORS.RATE}>
      {rankVisible && <RankLabel rank={goalRateRank} isLarge={false} />}
      <GoalRate grade={goalGrade}>{goalRate.toFixed(2)}%</GoalRate>
    </Cell>
  )
}

const Cell = styled(BaseCell)<{ rankVisible: boolean }>`
  justify-content: ${({ rankVisible }) => (rankVisible ? 'space-between' : 'flex-end')};
`
const GoalRate = styled('div')<{ grade: RateGrade }>`
  color: ${({ grade }) => getColorByRateGrade(grade)};
  font-weight: ${({ grade }) => getFontWeightByRateGrade(grade)};
`
