import * as React from 'react'
import styled from 'styled-components'
import { GRID_MIN_WIDTH } from '..'
import { PageSummary } from '../../../util/hooks/api/useContentReport'
import { PageSummaryBox } from './PageSummaryBox'
import { SCOPE_TYPE, useScopeType } from '../../../util/hooks/useScopeType'
import { HELP_TEXTS } from '../../../constants'

interface Props {
  readonly pageSummary: PageSummary
}

export function PageSummaryArea({ pageSummary: { sessions, users, conversions, conversionsRate } }: Props) {
  const { scopeType } = useScopeType()

  return (
    <Container>
      {scopeType === SCOPE_TYPE.SESSION && (
        <>
          <PageSummaryBox
            label={'セッション数'}
            value={sessions.toLocaleString()}
            helpText={HELP_TEXTS.SESSION_COUNT}
          />
          <PageSummaryBox
            label={'ゴール数'}
            value={conversions.toLocaleString()}
            helpText={HELP_TEXTS.SESSION_GOAL_COUNT}
          />
          <PageSummaryBox
            label={'ゴール率'}
            value={`${conversionsRate.toFixed(2)}%`}
            helpText={HELP_TEXTS.SESSION_GOAL_RATE}
          />
        </>
      )}
      {scopeType === SCOPE_TYPE.USER && (
        <>
          <PageSummaryBox label={'ユーザー数'} value={users.toLocaleString()} helpText={HELP_TEXTS.USER_COUNT} />
          <PageSummaryBox
            label={'ゴール数'}
            value={conversions.toLocaleString()}
            helpText={HELP_TEXTS.USER_GOAL_COUNT}
          />
          <PageSummaryBox
            label={'ゴール率'}
            value={`${conversionsRate.toFixed(2)}%`}
            helpText={HELP_TEXTS.USER_GOAL_RATE}
          />
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 36px;
  width: 100%;
  min-width: ${GRID_MIN_WIDTH}px;
  height: 40px;
  margin-bottom: 17px;
`
