import * as React from 'react'
import { Project, ProjectPvInfo } from './project'
import { Goal, GoalStep } from './goal'
import { Query, QueryStep } from './query'
import { Member, MemberStep } from './member'
import { Dimension } from './dimension'
import { IpAddress, IpAddressStep } from './ipAddress'
import { CrossDomain, CrossDomainStep } from './crossDomain'
import { SiteResponsive, SiteResponsiveStep } from './siteResponsive'
import { Invite, InviteStep } from './invite'
import { request } from '../../util/request'
import { Role } from '../../util/Authority'
import { getDateStringYM } from '../../util/Date'
import {
  ProjectData,
  ProjectsData,
  MembersData,
  PvData,
  UrlParametersData,
  AdminsData,
  CustomDimensionsData,
  IpaddressFiltersData,
  CrossDomainsData,
  SiteResponsiveData,
  MembersInviteData,
} from '../../util/Response'
import { GoalType, Condition, ValueCondition, MatchMap } from '../../util/Goal'
import { useToast } from '../../util/hooks/useToast'
import { GoalState } from '../../components/list/GoalList'
import { QueryState } from '../../components/list/QueryList'
import { MemberState, AdminState } from '../../components/list/MemberList'
import { DimensionState } from '../../components/list/DimensionList'
import { IpAddressState } from '../../components/list/IpAddressList'
import { CrossDomainState } from '../../components/list/CrossDomainList'
import { UserState } from '../../components/list/UserList'
import { LegendsState } from '../../components/charts/Legends'
import { GoalsData } from '../../util/hooks/api/Goal'
import { TeamPvResponse } from '../../util/hooks/api/useTeamPv'

export const CategoryType = {
  project: 0,
  trackingcode: 1,
  goal: 0,
  query: 0,
  dimension: 0,
  ipAddress: 0,
  crossDomain: 0,
  siteResponsive: 0,
  member: 0,
  invite: 1,
} as const

const CATEGORY_TITLE = [
  { category: 'project', title: 'プロジェクト情報' },
  { category: 'trackingcode', title: 'プロジェクト情報' },
  { category: 'goal', title: 'ゴール' },
  { category: 'ip_address', title: 'IPアドレスフィルター' },
  { category: 'site_responsive', title: 'PC・Mobile振り分け' },
  { category: 'query', title: 'クエリーパラメータ' },
  { category: 'cross_domain', title: 'クロスドメイン' },
  { category: 'dimension', title: 'カスタムディメンション' },
  { category: 'member', title: 'メンバー' },
  { category: 'invite', title: 'メンバー' },
] as const

interface PageContextType {
  readonly state: State
  readonly pageActions: Actions
}

export interface State {
  readonly loading: boolean
  readonly reload: boolean

  // プロジェクト情報
  readonly project: ProjectSettingsType

  // ゴール情報
  readonly goal: {
    readonly goals: GoalState[]
    readonly selectedId: number
    readonly disabled: boolean
    readonly createDisabled: boolean
    readonly isNameValid: boolean
    readonly isUrlValid: boolean
    readonly isConditionValid: boolean
    readonly isEventValid: boolean[]
    readonly type: number
    readonly name: string
    readonly condition: number
    readonly url: string
    readonly eventSelected: boolean[]
    readonly eventCondition: (number | null)[]
    readonly eventValue: string[]
    readonly baseName: string // 編集用
    readonly baseCondition: number // 編集用
    readonly baseUrl: string // 編集用
    readonly baseEventSelected: boolean[] // 編集用
    readonly baseEventCondition: (number | null)[] // 編集用
    readonly baseEventValue: string[] // 編集用
    readonly step: number
    readonly deleteId: number
    readonly deleteName: string
    readonly goalOptions: { label: string; value: number }[]
  }

  // クエリーパラメータ
  readonly query: {
    readonly queries: QueryState[]
    readonly selectedId: number
    readonly baseQuery: string
    readonly editQuery: string
    readonly disabled: boolean
    readonly step: number // 処理ステップ
  }

  // トラッキングコード
  readonly trackingCode: string

  // メンバー情報
  readonly member: {
    readonly members: MemberState[]
    readonly selectedId: number
    readonly selectedName: string
    readonly selectedRole: number
    readonly email: string
    readonly addDisabled: boolean
    readonly editDisabled: boolean
    readonly step: number // 処理ステップ
    readonly inviteMemberRole: number
    readonly inviteDisabled: boolean
    readonly isSearch: boolean // 検索状態
    readonly inviteMessage: string
  }

  // 組織管理者一覧
  readonly admins: AdminState[]

  // カスタムディメンション
  readonly dimension: {
    readonly dimensions: DimensionState[]
    readonly opened: boolean
    readonly index: number
    readonly name: string
    readonly scope: number
    readonly querySourced: boolean
    readonly activated: boolean
    readonly remained: number
    readonly disabled: boolean
    readonly created: boolean
    readonly editId: number
    readonly isConfirm: boolean
    readonly message: string
  }

  // IPアドレスフィルター
  readonly ipAddress: {
    readonly selectedId: number
    readonly disabled: boolean
    readonly name: string
    readonly condition: number
    readonly ipAddress: string
    readonly baseName: string
    readonly baseCondition: number
    readonly baseIpAddress: string
    readonly ipAddresses: IpAddressState[]
    readonly step: number // 処理ステップ
    readonly isNameValid: boolean
    readonly isConditionValid: boolean
    readonly isIpAddressValid: boolean
  }

  // クロスドメイン
  readonly crossDomain: {
    readonly crossDomains: CrossDomainState[]
    readonly selectedId: number
    readonly disabled: boolean
    readonly name: string
    readonly subdomain: boolean
    readonly baseName: string
    readonly baseSubdomain: boolean
    readonly step: number
    readonly isDomainValid: boolean
    readonly isSubdomainValid: boolean
  }

  // サイトレスポンシブ
  readonly siteResponsive: {
    readonly isUserAgent: boolean
    readonly mobileMaxWidth: number | string
    readonly tabletMinWidth: number | string
    readonly tabletMaxWidth: number | string
    readonly pcMinWidth: number | string

    // リセット可能かを調べる用
    readonly baseUserAgent: boolean
    readonly baseMobileMaxWidth: number | string
    readonly basePcMinWidth: number | string

    readonly disabled: boolean
    readonly isReset: boolean
    readonly step: number
  }

  // 招待ユーザー
  readonly invite: {
    readonly users: UserState[]
    readonly selectedId: number
    readonly selectedEmail: string
    readonly step: number // 処理ステップ
  }

  // 汎用
  readonly projectErrorMessage?: string
  readonly goalErrorMessage?: string
  readonly queryErrorMessage?: string
  readonly memberErrorMessage?: string
  readonly dimensionErrorMessage?: string
  readonly ipAddressErrorMessage?: string
  readonly crossDomainErrorMessage?: string
  readonly siteResponsiveErrorMessage?: string
  readonly inviteErrorMessage?: string
  readonly modalErrorMessage?: string
  readonly errorMessage?: string
  readonly toastMessage: string
}

export interface ProjectSettingsType {
  readonly id: number
  readonly name: string
  readonly url: string
  readonly baseName: string // 比較用
  readonly baseUrl: string // 比較用
  readonly nameDisabled: boolean
  readonly urlDisabled: boolean
  readonly disabled: boolean
  readonly updated: boolean
  readonly pvLimit: number // 組織の数値
  readonly pvCount: number // 組織の数値
  readonly teamPv: { label: string; value: number }[] // 組織の数値
  readonly projectPvOptions: { label: string; value: number }[] // プロジェクトの数値
  readonly projectPv: number // プロジェクトの数値
  readonly projectPvInfo: ProjectPvInfo[]
  readonly barData: any
  readonly barKeys: string[]
  readonly barMaxValue: number
  readonly checked: boolean
  readonly checkDisabled: boolean
  readonly selectedLabel: string
  readonly pvTotal: number // 選択されている月のPV合計
  readonly legends: LegendsState[]
}

export class Actions {
  project: Project
  goal: Goal
  query: Query
  member: Member
  dimension: Dimension
  ipAddress: IpAddress
  crossDomain: CrossDomain
  siteResponsive: SiteResponsive
  invite: Invite

  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly openToast: ({ message }: { message: string }) => void,
  ) {
    this.project = new Project(state, setState, openToast)
    this.goal = new Goal(state, setState)
    this.query = new Query(state, setState)
    this.member = new Member(state, setState)
    this.dimension = new Dimension(state, setState)
    this.ipAddress = new IpAddress(state, setState)
    this.crossDomain = new CrossDomain(state, setState)
    this.siteResponsive = new SiteResponsive(state, setState)
    this.invite = new Invite(state, setState)
  }

  fetch = async (id: string) => {
    try {
      const project: ProjectData = await request('GET', `/api/projects/${id}/`, true)
      const projects: ProjectsData = await request('GET', `/api/projects/`, true)
      const goals: GoalsData = await request('GET', `/api/projects/${id}/goals/`, true)
      const members: MembersData = await request('GET', `/api/projects/${id}/members/`, true)
      const pv: PvData = await request('GET', `/api/projects/${id}/pv/`, true)
      const teamPv: TeamPvResponse = await request('GET', `/api/team/pv/`, true)
      const urlParams: UrlParametersData = await request('GET', `/api/projects/${id}/url_parameters/`, true)
      const admins: AdminsData = await request('GET', `/api/admins/`, true)
      const dimensions: CustomDimensionsData = await request('GET', `/api/projects/${id}/custom_dimensions/`, true)
      const ipAddresses: IpaddressFiltersData = await request('GET', `/api/projects/${id}/ipaddress_filters/`, true)
      const crossDomains: CrossDomainsData = await request('GET', `/api/projects/${id}/cross_domains/`, true)
      const siteResponsive: SiteResponsiveData = await request('GET', `/api/projects/${id}/site_responsive/`, true)
      const inviteUsers: MembersInviteData = await request('GET', `/api/projects/${id}/members/invite/`, true)

      // プロジェクトの年月と一致する組織のPVを年月で検索
      const yearMonth = pv.page_views.length === 0 ? '' : pv.page_views[0].year_month
      const tempTeamPv =
        teamPv.page_views.length === 0 ? [] : teamPv.page_views.filter((view) => view.year_month === yearMonth)

      // PV情報作成
      let infos: ProjectPvInfo[] = []
      // 選択中のプロジェクトPV情報
      const info: ProjectPvInfo = {
        name: project.name,
        results:
          pv.page_views.length === 0
            ? [{ yearMonth: '', count: 0 }]
            : pv.page_views.map((view) => {
                return {
                  yearMonth: this.changeTimeString(view.year_month),
                  count: view.count,
                }
              }),
      }
      if (pv.page_views.length > 0) {
        infos.push(info)
      }

      // 選択していないプロジェクトのPV情報を纏める
      let otherInfo: ProjectPvInfo = { name: 'その他のプロジェクト', results: [] }
      for (let i = 0; i < projects.results.length; ++i) {
        const result = projects.results[i]
        if (result.id !== Number(id)) {
          const otherPv: PvData = await request('GET', `/api/projects/${result.id}/pv/`, true)
          for (let j = 0; j < otherPv.page_views.length; ++j) {
            const ym = this.changeTimeString(otherPv.page_views[j].year_month)
            // 指定の年月が登録済みか
            const index = otherInfo.results.findIndex((result) => result.yearMonth === ym)
            // インデックス見つかった
            if (index >= 0) {
              otherInfo.results[index].count += otherPv.page_views[j].count
            } else {
              otherInfo.results.push({
                yearMonth: ym,
                count: otherPv.page_views[j].count,
              })
            }
          }
        }
      }
      if (otherInfo.results.length > 0) {
        infos.push(otherInfo)
      }

      // グラフ用データ生成
      const { data, keys, count, legends } = this.project.createBarData(
        infos,
        pv.page_views.length === 0 ? '' : this.changeTimeString(pv.page_views[0].year_month),
      )

      // 当月以外は最適化のチェックボックスを操作できないのでチェック
      const disabled = !(getDateStringYM(new Date()) === this.changeTimeString(yearMonth))

      // タブレットの幅を計算
      const { minWidth, maxWidth } = this.siteResponsive.getTabletWidth(
        siteResponsive.mobile_max_width,
        siteResponsive.pc_min_width,
      )
      this.state.toastMessage && this.openToast({ message: this.state.toastMessage })
      this.setState({
        ...this.state,
        loading: false,
        reload: false,
        project: {
          ...this.state.project,
          id: project.id,
          name: project.name,
          url: project.url,
          baseName: project.name,
          baseUrl: project.url,
          pvLimit: teamPv.limit,
          pvCount: tempTeamPv.length === 0 ? 0 : tempTeamPv[0].count,
          teamPv:
            teamPv.page_views.length === 0
              ? []
              : teamPv.page_views.map((view) => {
                  return {
                    label: this.changeTimeString(view.year_month),
                    value: view.count,
                  }
                }),
          projectPvOptions:
            pv.page_views.length === 0
              ? []
              : pv.page_views.map((view) => {
                  return {
                    label: this.changeTimeString(view.year_month),
                    value: view.count,
                  }
                }),
          projectPv: pv.page_views.length === 0 ? 0 : pv.page_views[0].count,
          projectPvInfo: infos,
          barData: [data],
          barKeys: keys,
          barMaxValue: getDateStringYM(new Date()) === this.changeTimeString(yearMonth) ? teamPv.limit : count, // 当月を表示できる場合はPV上限値
          checkDisabled: disabled,
          selectedLabel: infos.length === 0 ? '' : infos[0].results[0].yearMonth,
          pvTotal: count,
          legends: legends,
        },
        goal: {
          ...this.state.goal,
          goals:
            goals.results.length === 0
              ? []
              : goals.results.map((goal) => {
                  if (goal.goal_type === GoalType.url) {
                    return {
                      id: goal.id,
                      name: goal.name,
                      type: goal.goal_type,
                      url: goal.goal_url
                        ? {
                            condition: goal.goal_url.condition,
                            url: goal.goal_url.url,
                          }
                        : undefined,
                    }
                  }
                  return {
                    id: goal.id,
                    name: goal.name,
                    type: goal.goal_type,
                    event: goal.goal_event
                      ? {
                          category: goal.goal_event.category,
                          action: goal.goal_event.action,
                          label: goal.goal_event.label,
                          value: goal.goal_event.value === null ? 0 : goal.goal_event.value,
                          category_condition: goal.goal_event.category_condition,
                          action_condition: goal.goal_event.action_condition,
                          label_condition: goal.goal_event.label_condition,
                          value_condition: goal.goal_event.value_condition,
                        }
                      : undefined,
                  }
                }),
        },
        query: {
          ...this.state.query,
          queries:
            urlParams.results.length === 0
              ? []
              : urlParams.results.map((param) => {
                  return {
                    id: param.id,
                    name: param.name,
                  }
                }),
        },
        trackingCode: project.tag,
        member: {
          ...this.state.member,
          members:
            members.results.length === 0
              ? []
              : members.results.map((member) => {
                  return {
                    id: member.id,
                    userName: member.user.username,
                    email: member.user.email,
                    role: member.role,
                    roleName: member.role_name,
                  }
                }),
        },
        admins:
          admins.results.length === 0
            ? []
            : admins.results.map((admin) => {
                return {
                  id: admin.id,
                  name: admin.username,
                  email: admin.email,
                }
              }),
        dimension: {
          ...this.state.dimension,
          dimensions:
            dimensions.results.length === 0
              ? []
              : dimensions.results.map((dimension) => {
                  return {
                    id: dimension.id,
                    index: dimension.index,
                    name: dimension.name,
                    scope: dimension.scope,
                    querySourced: dimension.query_sourced,
                    activated: dimension.activated,
                  }
                }),
          remained: dimensions.remained_registrations,
        },
        ipAddress: {
          ...this.state.ipAddress,
          ipAddresses:
            ipAddresses.results.length === 0
              ? []
              : ipAddresses.results.map((ipaddress) => {
                  return {
                    id: ipaddress.id,
                    name: ipaddress.name,
                    condition: ipaddress.match_type,
                    ipAddress: ipaddress.ipaddress,
                  }
                }),
        },
        crossDomain: {
          ...this.state.crossDomain,
          crossDomains:
            crossDomains.results.length === 0
              ? []
              : crossDomains.results.map((crossDomain) => {
                  return {
                    id: crossDomain.id,
                    name: crossDomain.name,
                    allow_subdomain: crossDomain.allow_subdomain,
                    updatedAt: crossDomain.updated_at,
                    createdAt: crossDomain.created_at,
                  }
                }),
        },
        siteResponsive: {
          ...this.state.siteResponsive,
          isUserAgent: siteResponsive.mobile_max_width === null && siteResponsive.pc_min_width === null,
          mobileMaxWidth: siteResponsive.mobile_max_width === null ? '' : siteResponsive.mobile_max_width,
          tabletMinWidth: minWidth,
          tabletMaxWidth: maxWidth,
          pcMinWidth: siteResponsive.pc_min_width === null ? '' : siteResponsive.pc_min_width,
          baseUserAgent: siteResponsive.mobile_max_width === null && siteResponsive.pc_min_width === null,
          baseMobileMaxWidth: siteResponsive.mobile_max_width === null ? '' : siteResponsive.mobile_max_width,
          basePcMinWidth: siteResponsive.pc_min_width === null ? '' : siteResponsive.pc_min_width,
          disabled: true,
          isReset: false,
        },
        invite: {
          ...this.state.invite,
          users: inviteUsers.results.map((result) => {
            return {
              id: result.id,
              userName: '',
              email: result.email,
              role: result.role,
              roleName: '',
            }
          }),
        },
        errorMessage: '',
        projectErrorMessage: '',
        goalErrorMessage: '',
        queryErrorMessage: '',
        memberErrorMessage: '',
        siteResponsiveErrorMessage: '',
        toastMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        errorMessage: typeof e === 'string' ? e : 'プロジェクト情報の取得に失敗しました。',
      })
    }
  }

  // 日時の文字列をラベル用に変換
  changeTimeString = (str: string) => {
    return str.slice(0, 4) + '/' + str.slice(4)
  }

  getCategoryTitle = (categoryStr: string) => {
    return CATEGORY_TITLE.find(({ category }) => category === categoryStr)?.title || ''
  }

  // スナックバーリセット
  resetState = () => {
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        updated: false,
      },
    })
  }
}

const initialState: State = {
  loading: true,
  reload: false,
  project: {
    id: 0,
    name: '',
    url: '',
    baseName: '',
    baseUrl: '',
    nameDisabled: true,
    urlDisabled: true,
    disabled: true,
    updated: false,
    pvLimit: 0,
    pvCount: 0,
    teamPv: [],
    projectPvOptions: [],
    projectPv: 0,
    projectPvInfo: [],
    barData: {},
    barKeys: [],
    barMaxValue: 0,
    checked: true,
    checkDisabled: false,
    selectedLabel: '',
    pvTotal: 0,
    legends: [],
  },
  goal: {
    goals: [],
    selectedId: 0,
    disabled: true,
    createDisabled: true,
    isNameValid: true,
    isConditionValid: true,
    isUrlValid: true,
    isEventValid: [false, false, false, false],
    type: GoalType.url,
    name: '',
    condition: 0,
    url: '',
    eventSelected: [true, true, true, true],
    eventCondition: [Condition.perfect, Condition.perfect, Condition.perfect, ValueCondition.greater],
    eventValue: ['', '', '', ''],
    baseName: '',
    baseCondition: 0,
    baseUrl: '',
    baseEventSelected: [true, true, true, true],
    baseEventCondition: [Condition.perfect, Condition.perfect, Condition.perfect, ValueCondition.greater],
    baseEventValue: ['', '', '', ''],
    step: GoalStep.none,
    deleteId: 0,
    deleteName: '',
    goalOptions: [
      { label: MatchMap[Condition.perfect], value: Condition.perfect },
      { label: MatchMap[Condition.head], value: Condition.head },
      { label: MatchMap[Condition.regexp], value: Condition.regexp },
    ],
  },
  query: {
    queries: [],
    selectedId: 0,
    baseQuery: '',
    editQuery: '',
    disabled: true,
    step: QueryStep.none,
  },
  trackingCode: '',
  member: {
    members: [],
    selectedId: 0,
    selectedName: '',
    selectedRole: Role.Project.none,
    email: '',
    addDisabled: true,
    editDisabled: true,
    step: MemberStep.none,
    inviteMemberRole: Role.Project.member,
    inviteDisabled: true,
    isSearch: false,
    inviteMessage: '',
  },
  admins: [],
  dimension: {
    dimensions: [],
    opened: false,
    index: 0,
    name: '',
    scope: 1,
    querySourced: false,
    activated: true,
    remained: 0,
    disabled: true,
    created: true,
    editId: 0,
    isConfirm: false,
    message: '',
  },
  ipAddress: {
    selectedId: 0,
    disabled: true,
    name: '',
    condition: 0,
    ipAddress: '',
    baseName: '',
    baseCondition: 0,
    baseIpAddress: '',
    ipAddresses: [],
    step: IpAddressStep.none,
    isNameValid: false,
    isConditionValid: false,
    isIpAddressValid: false,
  },
  crossDomain: {
    crossDomains: [],
    selectedId: 0,
    disabled: true,
    name: '',
    subdomain: false,
    baseName: '',
    baseSubdomain: false,
    step: CrossDomainStep.none,
    isDomainValid: false,
    isSubdomainValid: false,
  },
  siteResponsive: {
    isUserAgent: true,
    mobileMaxWidth: '',
    tabletMinWidth: '',
    tabletMaxWidth: '',
    pcMinWidth: '',
    baseUserAgent: false,
    baseMobileMaxWidth: '',
    basePcMinWidth: '',
    disabled: true,
    isReset: false,
    step: SiteResponsiveStep.none,
  },
  invite: {
    users: [],
    selectedId: 0,
    selectedEmail: '',
    step: InviteStep.none,
  },
  projectErrorMessage: '',
  goalErrorMessage: '',
  memberErrorMessage: '',
  crossDomainErrorMessage: '',
  siteResponsiveErrorMessage: '',
  inviteErrorMessage: '',
  modalErrorMessage: '',
  toastMessage: '',
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const { openToast } = useToast()
  const pageActions = new Actions(state, setState, openToast)
  return { state, pageActions }
}
