import * as React from 'react'
import { navigate, Redirect } from '@gatsbyjs/reach-router'

import { GlobalContext } from '../../../GlobalState'
import { Authority } from '../../../util/Authority'
import { Loading } from '../../../components/common/Loading'
import { ProjectContext } from '../../ProjectRoot'
import { CanNotAddMessage } from '../../../components/warnings/CanNotAddMessage'
import { EditReport } from '../EditReport'
import { useFunnelReports } from '../../../util/hooks/api/FunnelReport/useFunnelReports'
import { useCreateFunnelReport } from '../../../util/hooks/api/FunnelReport/useCreateFunnelReport'
import { INIT_EDIT_DATA } from '../../../util/hooks/api/FunnelReport/constants'
import { useReportSettings } from '../../../util/hooks/useReportSettings'
import { ErrorBox } from '../../../components/common/ErrorBox'

/**
 * ファネルレポートのレポート新規作成エリアをレンダリングする
 *
 * @param {object} param - The input parameters for the method.
 * @param {React.RefObject} param.mainAreaRef - MainAreaの参照オブジェクト（各ページのスクロール処理に使用）
 */
export function CreateReportArea() {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const {
    state: { projectId, baseUrl },
  } = React.useContext(ProjectContext)

  const canAdd = React.useMemo(
    () => Authority.canAddFunnelReport(AccountInfo.permissions, projectId),
    [AccountInfo.permissions, projectId],
  )

  const { isChecking: isSettingsChecking, isCompleted: isSettingsCompleted } = useReportSettings({ projectId })

  const {
    data: reportsData,
    isLoading: isReportsLoading,
    resetFunnelReports,
  } = useFunnelReports({ projectId, enabled: canAdd })

  const {
    mutate: createFunnelReportMutate,
    isLoading: isCreating,
    isError: isCreateError,
  } = useCreateFunnelReport({ projectId })

  const onInvalidate = () => {
    resetFunnelReports()
  }

  const handleCancel = () => {
    navigate(`${baseUrl}report/funnel/`)
  }

  if (!canAdd) return <CanNotAddMessage />
  if (isSettingsChecking || isReportsLoading || isCreating) return <Loading />
  if (!isSettingsCompleted || reportsData?.reportLimitReached) {
    return <Redirect to={`${baseUrl}report/funnel`} noThrow />
  }

  return (
    <>
      {isCreateError && <ErrorBox>ファネルレポートの保存に失敗しました。</ErrorBox>}
      <EditReport
        data={INIT_EDIT_DATA}
        saveMutate={createFunnelReportMutate}
        onInvalidate={onInvalidate}
        onCancel={handleCancel}
      />
    </>
  )
}
