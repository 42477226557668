import * as React from 'react'
import styled from 'styled-components'

import { Content } from '../../../util/hooks/api/useContentReport'
import { ContentGroup } from '../../../util/hooks/api/useContentGroupReport'
import { GRID_BORDER_COLOR } from '../../../styleConstants'
import { GRID_ITEM_HEIGHT, GRID_MIN_WIDTH, REGISTER_FOOTER_HEIGHT } from '..'
import { useQuadrantChart } from '../../../util/hooks/cookie/useQuadrantChart'

interface Props {
  readonly children: React.ReactNode
  readonly gridScrollTo: number | null
  readonly contents: Content[] | ContentGroup[]
  readonly onReset?: () => void
}

export function GridContentsListArea({ children, gridScrollTo, contents, onReset }: Props) {
  const ref = React.useRef<HTMLDivElement>(null)

  // グラフエリアを開いた際やブラウザ拡大縮小された際、一括登録Footerと表が重ならないよう空のdivを表示する
  const [needsEmptyBox, setNeedsEmptyBox] = React.useState(false)
  const calcNeedsEmptyBox = () => {
    setNeedsEmptyBox((ref.current?.offsetHeight || 0) <= GRID_LIST_MIN_HEIGHT)
  }
  React.useEffect(() => {
    // リサイズした際に表の行数が変わる可能性があるため、再計算する
    window.addEventListener('resize', calcNeedsEmptyBox)

    // Cleanup function
    return () => {
      window.removeEventListener('resize', calcNeedsEmptyBox)
    }
  }, [])

  const { chartOpened } = useQuadrantChart()
  React.useEffect(() => {
    // アコーディオン開閉はsmooth動作のため数ms待機し、再計算する
    setTimeout(() => {
      setNeedsEmptyBox((ref.current?.offsetHeight || 0) <= GRID_LIST_MIN_HEIGHT)
    }, 300)
  }, [chartOpened])

  React.useEffect(() => {
    if (!gridScrollTo) return

    const targetIndex = contents.findIndex((content) => content.id === gridScrollTo)
    const targetElement = ref.current?.children[0]?.children[targetIndex] as HTMLElement
    if (!targetElement) return
    // MEMO: 行の高さは複数タグ登録で変わることがあるため、offsetTopで正確なスクロール位置を取得する必要があった
    // 対応issue: https://github.com/uncovertruth/content-analytics/issues/2156
    const top = targetElement.offsetTop
    ref.current?.scrollTo({ behavior: 'smooth', top: top })
    onReset!()
  }, [gridScrollTo])

  return (
    <>
      <Container ref={ref}>
        <List>{children}</List>
      </Container>
      {needsEmptyBox && <EmptyBox />}
    </>
  )
}

const GRID_LIST_MIN_HEIGHT = GRID_ITEM_HEIGHT * 5

const Container = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  min-width: ${GRID_MIN_WIDTH}px;
  min-height: ${GRID_LIST_MIN_HEIGHT}px; // 領域が小さくなっても表の5行分程度は表示されるよう確保する
`
const List = styled.div`
  cursor: pointer;
  border: 1px solid ${GRID_BORDER_COLOR};
  position: relative; // 子要素でoffsetTopを取得する際に基準とするために必要
`

const EmptyBox = styled.div`
  min-height: ${REGISTER_FOOTER_HEIGHT}px;
`
