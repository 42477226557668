import getWindow from './getWindow';
/**
 * Gets the number of pixels to scroll the element's content from the left edge.
 * @param node The DOM element
 */

function scrollLeft(node, val) {
  var win = getWindow(node);
  var left = node.scrollLeft;
  var top = 0;

  if (win) {
    left = win.pageXOffset;
    top = win.pageYOffset;
  }

  if (val !== undefined) {
    if (win) {
      win.scrollTo(val, top);
    } else {
      node.scrollLeft = val;
    }
  }

  return left;
}

export default scrollLeft;