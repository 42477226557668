import _extends from "@babel/runtime/helpers/esm/extends";
import getOffsetParent from './getOffsetParent';
import getOffset from './getOffset';
import getStyle from './getStyle';
import scrollTop from './scrollTop';
import scrollLeft from './scrollLeft';
import nodeName from './nodeName';
/**
 * Get the position of a DOM element
 * @param node  The DOM element
 * @param offsetParent  The offset parent of the DOM element
 * @param calcMargin  Whether to calculate the margin
 * @returns  The position of the DOM element
 */

export default function getPosition(node, offsetParent, calcMargin) {
  if (calcMargin === void 0) {
    calcMargin = true;
  }

  var parentOffset = {
    top: 0,
    left: 0
  };
  var offset = null; // Fixed elements are offset from window (parentOffset = {top:0, left: 0},
  // because it is its only offset parent

  if (getStyle(node, 'position') === 'fixed') {
    offset = node.getBoundingClientRect();
  } else {
    offsetParent = offsetParent || getOffsetParent(node);
    offset = getOffset(node);

    if (nodeName(offsetParent) !== 'html') {
      var nextParentOffset = getOffset(offsetParent);

      if (nextParentOffset) {
        parentOffset.top = nextParentOffset.top;
        parentOffset.left = nextParentOffset.left;
      }
    }

    parentOffset.top += parseInt(getStyle(offsetParent, 'borderTopWidth'), 10) - scrollTop(offsetParent) || 0;
    parentOffset.left += parseInt(getStyle(offsetParent, 'borderLeftWidth'), 10) - scrollLeft(offsetParent) || 0;
  } // Subtract parent offsets and node margins


  if (offset) {
    var marginTop = calcMargin ? parseInt(getStyle(node, 'marginTop'), 10) || 0 : 0;
    var marginLeft = calcMargin ? parseInt(getStyle(node, 'marginLeft'), 10) || 0 : 0;
    return _extends({}, offset, {
      top: offset.top - parentOffset.top - marginTop,
      left: offset.left - parentOffset.left - marginLeft
    });
  }

  return null;
}