import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'
import { getDateStringYM } from '../../util/Date'
import { ProjectData } from '../../util/Response'
import { LegendsState } from '../../components/charts/Legends'

// プロジェクト別のPV情報
export interface ProjectPvInfo {
  readonly name: string
  readonly results: Array<{
    readonly yearMonth: string
    count: number
  }>
}

export class Project {
  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly openToast: ({ message }: { message: string }) => void,
  ) {}

  // プロジェクト名更新
  updateName = (event: React.FormEvent<HTMLInputElement>) => {
    const valid = !(event.currentTarget.validity.valid && this.state.project.baseName !== event.currentTarget.value)
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        name: event.currentTarget.value,
        nameDisabled: valid,
        disabled: valid && this.state.project.urlDisabled,
      },
    })
  }

  // URL更新
  updateUrl = (event: React.FormEvent<HTMLInputElement>) => {
    const valid = !(event.currentTarget.validity.valid && this.state.project.baseUrl !== event.currentTarget.value)
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        url: event.currentTarget.value,
        urlDisabled: valid,
        disabled: valid && this.state.project.nameDisabled,
      },
    })
  }

  onCancel = () => {
    // 入力の変更をリセット
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        name: this.state.project.baseName,
        url: this.state.project.baseUrl,
        nameDisabled: true,
        urlDisabled: true,
        disabled: true,
      },
    })
  }

  onApply = async () => {
    try {
      const project: ProjectData = await request(
        'PATCH',
        `/api/projects/${this.state.project.id}/`,
        true,
        JSON.stringify({ name: this.state.project.name, url: this.state.project.url }),
      )
      this.openToast({ message: 'プロジェクト情報を変更しました' })

      this.setState({
        ...this.state,
        project: {
          ...this.state.project,
          name: project.name,
          url: project.url,
          id: project.id,
          baseName: project.name,
          baseUrl: project.url,
          nameDisabled: true,
          urlDisabled: true,
          disabled: true,
          updated: true,
        },
        toastMessage: '',
        projectErrorMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        projectErrorMessage: typeof e === 'string' ? e : 'プロジェクトの更新に失敗しました。',
      })
    }
  }

  // PV表示切り替え
  onPvChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // 同月の組織のPV数を取得する
    const label = e.target.options[e.target.selectedIndex].label
    const teamPv = this.state.project.teamPv.filter((pv) => pv.label === label)
    const { data, keys, count, legends } = this.createBarData(this.state.project.projectPvInfo, label)
    const disabled = !(getDateStringYM(new Date()) === label) // 当月以外は最適化のチェックボックスを操作できない
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        projectPv: Number(e.target.value),
        pvCount: teamPv.length === 0 ? 0 : teamPv[0].value,
        barData: [data],
        barKeys: keys,
        pvTotal: count,
        barMaxValue: disabled ? count : this.state.project.checked ? this.state.project.pvLimit : count,
        checkDisabled: disabled,
        selectedLabel: label, // 選択しているラベルを保持
        legends: legends,
      },
    })
  }

  // グラフのX軸最適化を行うチェックボックス
  onClickOptimize = () => {
    const check = !this.state.project.checked
    const maxValue = check ? this.state.project.pvLimit : this.state.project.pvTotal
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        checked: check,
        barMaxValue: maxValue,
      },
    })
  }

  // 文字列を数値に変換する
  stringToNumber = (str: string) => {
    // 文字列 -> 配列 -> 文字コードの配列 -> 全部足す
    return Array.from(str)
      .map((ch) => ch.charCodeAt(0))
      .reduce((a, b) => a + b)
  }

  // グラフデータ作成
  createBarData = (infos: ProjectPvInfo[], yearMonth: string) => {
    let data = { title: 'PV' }
    let keys: string[] = []
    let count = 0
    let legends: LegendsState[] = []
    infos.map((info) => {
      const counts = info.results.filter((result) => result.yearMonth === yearMonth)
      if (counts.length > 0) {
        const n = this.stringToNumber(info.name)
        const d = {
          [`${info.name}`]: counts[0].count,
          [`${info.name} Color`]: `hsl(${(n * n) % 360}, 80%, 64%)`,
        }
        const l: LegendsState = { name: info.name, color: `hsl(${(n * n) % 360}, 80%, 64%)` }
        data = { ...data, ...d }
        keys.push(info.name)
        count += counts[0].count
        legends.push(l)
      }
    })

    return { data, keys, count, legends }
  }

  // グラフのカラー設定関数
  setColor = (bar: any) => {
    const n = this.stringToNumber(bar.id)
    return `hsl(${(n * n) % 360}, 80%, 64%)`
  }

  // プロジェクトPVリストから最も長い名前の文字列数を取得
  getProjectNameNum = () => {
    let len = 0
    this.state.project.projectPvInfo.forEach((info) => {
      if (info.name.length > len) {
        len = info.name.length
      }
    })
    return len
  }
}
