import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Accordion, AccordionSummary, AccordionDetails, AccordionProps } from '@mui/material'
import { ChevronThinDown } from '@styled-icons/entypo'
import { StyledIconBase } from '@styled-icons/styled-icon'

interface Props {
  readonly title: string | React.ReactNode
  readonly icon: React.ReactNode
  readonly children: React.ReactNode
  readonly isAdmin?: boolean
  readonly dataTestId?: string
  readonly accordionProps?: Omit<AccordionProps, 'children'>
  readonly contentPadding?: number
}

export const AccordionBox = forwardRef<HTMLDivElement, Props>(
  ({ title, icon, children, isAdmin = false, dataTestId = '', accordionProps, contentPadding }, ref) => {
    return (
      <StyledAccordion ref={ref} {...accordionProps} disableGutters>
        <StyledAccordionSummary
          expandIcon={<ChevronThinDown size={35} color={colors.contentBlue.blue3} />}
          data-testid={dataTestId}
        >
          <Title isAdmin={isAdmin}>
            <IconWrapper isAdmin={isAdmin}>{icon}</IconWrapper>
            {title}
          </Title>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <ContentBox padding={contentPadding}>{children}</ContentBox>
        </StyledAccordionDetails>
      </StyledAccordion>
    )
  },
)

const StyledAccordion = styled(Accordion)`
  background: none;
  box-shadow: none;
  &:before {
    opacity: 0;
  }
`

const StyledAccordionSummary = styled(AccordionSummary)`
  margin: 0;
  padding: 8px 32px 8px 40px;
  background-color: ${colors.white};
`

const Title = styled.div<{ isAdmin: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  color: ${({ isAdmin }) => (isAdmin ? colors.orange : colors.headerBg)};
`

const IconWrapper = styled.div<{ isAdmin: boolean }>`
  ${StyledIconBase} {
    color: ${({ isAdmin }) => (isAdmin ? colors.orange : colors.headerBg)};
    margin: 0 8px 0 0;
    width: 24px;
    height: 24px;
  }
`

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 0;
`

const ContentBox = styled.div<{ padding?: number }>`
  background-color: ${colors.white};
  padding: ${({ padding }) => (padding !== undefined ? `${padding}px` : '32px')};
  margin-top: 8px;
`
