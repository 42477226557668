import * as React from 'react'
import styled from 'styled-components'
import { Link, navigate } from '@gatsbyjs/reach-router'
import { DATA_BAR_COLORS, GRID_BORDER_COLOR, colors } from '../../styleConstants'
import { OpenInNew } from '@styled-icons/material/OpenInNew'
import { SortIconState } from '../common/SortButton'
import { SCOPE_TYPE, useScopeType } from '../../util/hooks/useScopeType'
import { InteractiveTooltip, TOOLTIP_DELAY } from '../Tooltip'
import { InformationTooltip } from '../Tooltip/IconTooltip'

export const gridLayout = {
  minWidth: '820px',
  maxWidth: '1400px',
  headerHeight: '83px',
  height: '55px',
  row: {
    index: '60px',
    url: '300px',
    icon: '30px',
    viewCount: '126px',
    pageLandingCount: '126px',
    pageBounceRate: '90px',
    pageExitRate: '90px',
    pageConversions: '119px',
    pageConversionsRate: '90px',
  },
  fontSize: '12px',
}

export const gridLayoutRowGroups = {
  url: parseInt(gridLayout.row.index) + parseInt(gridLayout.row.url) + parseInt(gridLayout.row.icon) + 'px',
  withoutUrl:
    parseInt(gridLayout.row.viewCount) +
    parseInt(gridLayout.row.pageLandingCount) +
    parseInt(gridLayout.row.pageBounceRate) +
    parseInt(gridLayout.row.pageExitRate) +
    parseInt(gridLayout.row.pageConversions) +
    parseInt(gridLayout.row.pageConversionsRate) +
    'px',
}

export interface PageReportItem {
  readonly id: number | null
  readonly url: string
  readonly title: string
  readonly pageSessions: number
  readonly pageSessionsScaledPercent: number
  readonly pageSessionsOverallRatio: number
  readonly pageUsers: number
  readonly pageUsersScaledPercent: number
  readonly pageUsersOverallRatio: number
  readonly pageLandingCount: number
  readonly pageLandingCountScaledPercent: number
  readonly pageLandingCountOverallRatio: number
  readonly pageBounceRate: number
  readonly pageBounceRateScaledPercent: number
  readonly pageExitRate: number
  readonly pageExitRateScaledPercent: number
  readonly pageConversions: number
  readonly pageConversionsScaledPercent: number
  readonly pageConversionsOverallRatio: number
  readonly pageConversionsRate: number
  readonly pageConversionsRateScaledPercent: number
  readonly contentEventsExists: boolean
}
export type PageReportSortKey = keyof PageReportSortState

export interface PageReportSortState {
  id: SortIconState
  url: SortIconState
  title: SortIconState
  page_sessions: SortIconState
  page_users: SortIconState
  page_landing_count: SortIconState
  page_bounce_rate: SortIconState
  page_exit_rate: SortIconState
  page_conversions: SortIconState
  page_conversions_rate: SortIconState
}

interface Props {
  readonly projectId: string
  readonly item: PageReportItem
  readonly dataIndex: number
  readonly baseUrl?: string
}

export function PageGridItem({ item, dataIndex, baseUrl }: Props) {
  const { scopeType } = useScopeType()

  const [viewCount, viewScaledPercent, viewOverallRatio] =
    scopeType === SCOPE_TYPE.USER
      ? [item.pageUsers, item.pageUsersScaledPercent, item.pageUsersOverallRatio]
      : [item.pageSessions, item.pageSessionsScaledPercent, item.pageSessionsOverallRatio]

  const onClick = (url: string) => {
    navigate(url)
  }
  const url = `${baseUrl}report/content/${item.id}${window.location.search}`

  const linkDisabled = !item.contentEventsExists

  return (
    <Container linkDisabled={linkDisabled}>
      <RowUrl>
        <Index>{dataIndex}</Index>
        <Box
          linkDisabled={linkDisabled}
          {...(!linkDisabled && {
            onClick: () => onClick(url),
          })}
        >
          <InteractiveTooltip
            content={
              <>
                {item.url}
                <br />
                {item.title}
              </>
            }
            delay={TOOLTIP_DELAY}
          >
            <div>
              <Url>{linkDisabled ? <>{item.url}</> : <DesignedLink to={url}>{item.url}</DesignedLink>}</Url>
              <Title>{item.title}</Title>
            </div>
          </InteractiveTooltip>
        </Box>

        {linkDisabled && (
          <IconBox>
            <InformationTooltip width={300} iconSize="20px" iconColor={colors.lightBlue}>
              検索対象期間に当ページのデータが計測されていないため
              <br />
              コンテンツレポートへ遷移できません。
            </InformationTooltip>
          </IconBox>
        )}

        <IconBox>
          <a href={`${item.url}`} target="_blank" rel="noopener noreferrer">
            <OpenInNew size={18} color={`${colors.gray600}`} />
          </a>
        </IconBox>
      </RowUrl>
      <AdjustContainer>
        <RowNarrow barPercent={viewScaledPercent} barColor={DATA_BAR_COLORS.COUNT} width={gridLayout.row.viewCount}>
          <Main>{viewCount.toLocaleString()}</Main>
          <Sub>({viewOverallRatio.toFixed(1)}%)</Sub>
        </RowNarrow>
        <RowNarrow
          barPercent={item.pageLandingCountScaledPercent}
          barColor={DATA_BAR_COLORS.COUNT}
          width={gridLayout.row.pageLandingCount}
        >
          <Main>{item.pageLandingCount.toLocaleString()}</Main>
          <Sub>({item.pageLandingCountOverallRatio.toFixed(1)}%)</Sub>
        </RowNarrow>
        <RowNarrow
          barPercent={item.pageBounceRateScaledPercent}
          barColor={DATA_BAR_COLORS.RATE}
          width={gridLayout.row.pageBounceRate}
        >
          {item.pageBounceRate.toFixed(1)}%
        </RowNarrow>
        <RowNarrow
          barPercent={item.pageExitRateScaledPercent}
          barColor={DATA_BAR_COLORS.RATE}
          width={gridLayout.row.pageExitRate}
        >
          {item.pageExitRate.toFixed(1)}%
        </RowNarrow>
        <RowNarrow
          barPercent={item.pageConversionsScaledPercent}
          barColor={DATA_BAR_COLORS.COUNT}
          width={gridLayout.row.pageConversions}
        >
          <Main>{item.pageConversions.toLocaleString()}</Main>
          <Sub>({item.pageConversionsOverallRatio.toFixed(1)}%)</Sub>
        </RowNarrow>
        <RowNarrow
          barPercent={item.pageConversionsRateScaledPercent}
          barColor={DATA_BAR_COLORS.RATE}
          width={gridLayout.row.pageConversionsRate}
        >
          {item.pageConversionsRate.toFixed(2)}%
        </RowNarrow>
      </AdjustContainer>
    </Container>
  )
}

const Container = styled.div<{ linkDisabled: boolean }>`
  display: flex;
  align-items: stretch;
  width: 100%;
  min-height: ${gridLayout.height};
  box-sizing: border-box;
  white-space: normal;
  background-color: inherit;
  ${(props) => props.linkDisabled && `background: ${colors.gray200};`}
`

const Row = styled.div<{ barPercent?: number; barColor?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${gridLayout.row.url};
  height: 100%;
  min-height: ${gridLayout.height};
  font-size: 14px;
  border-left: 1px solid ${GRID_BORDER_COLOR};
  border-bottom: 1px solid ${GRID_BORDER_COLOR};
  box-sizing: border-box;

  background: linear-gradient(
    to right,
    ${(props) => props.barColor + ' ' + props.barPercent + '%'},
    transparent ${(props) => props.barPercent + '%'}
  );
  background-size: 100% 24px;
  background-repeat: no-repeat;
  background-position: center;

  &:last-child {
    border-right: 1px solid ${GRID_BORDER_COLOR};
  }
`
const RowUrl = styled(Row)`
  justify-content: space-between;
  width: 100%;
  background-color: inherit;
  min-width: ${gridLayoutRowGroups.url};
`
const RowNarrow = styled(Row)<{ width: string }>`
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  padding: 0 14px;
  width: ${(props) => props.width};
  max-width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  background-color: inherit;
`

const Main = styled.div`
  font-size: 14px;
`
const Sub = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: ${colors.gray750};
`

const AdjustContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: inherit;
`

const Index = styled.div`
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: ${gridLayout.row.index};
  max-width: ${gridLayout.row.index};
  height: 100%;
  min-height: ${gridLayout.height};
`

const Box = styled.div<{ linkDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  width: calc(100% - ${gridLayout.row.index} - (${gridLayout.row.icon} * ${(props) => (props.linkDisabled ? 2 : 1)}));
  overflow: hidden;
  ${(props) => !props.linkDisabled && `cursor: pointer;`}
`

const Url = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const DesignedLink = styled(Link)`
  word-break: break-all;
  line-height: 14px;
  color: ${colors.link.base};

  &:visited {
    color: ${colors.link.visited};
  }
`

const Title = styled.div`
  font-size: ${gridLayout.fontSize};
  color: ${colors.gray500};
  line-height: 14px;
  margin-top: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${gridLayout.row.icon};
  min-width: ${gridLayout.row.icon};
  height: 100%;
  min-height: ${gridLayout.height};
`
