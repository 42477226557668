import hyphenateStyleName from './utils/hyphenateStyleName';
import removeStyle from './removeStyle';

function addStyle(node, property, value) {
  var css = '';
  var props = property;

  if (typeof property === 'string') {
    if (value === undefined) {
      throw new Error('value is undefined');
    }

    (props = {})[property] = value;
  }

  if (typeof props === 'object') {
    for (var _key in props) {
      if (Object.prototype.hasOwnProperty.call(props, _key)) {
        if (!props[_key] && props[_key] !== 0) {
          removeStyle(node, hyphenateStyleName(_key));
        } else {
          css += hyphenateStyleName(_key) + ":" + props[_key] + ";";
        }
      }
    }
  }

  node.style.cssText += ";" + css;
}

export default addStyle;