import * as React from 'react'
import styled from 'styled-components'
import { GRID_BORDER_COLOR, colors } from '../../styleConstants'
import {
  PageGridItem,
  PageReportItem,
  gridLayout,
  PageReportSortState,
  PageReportSortKey,
  gridLayoutRowGroups,
} from './PageGridItem'
import { SortButton } from '../common/SortButton'
import { SCOPE_TYPE, useScopeType } from '../../util/hooks/useScopeType'
import { SummaryRow } from '../PageReport/rows/SummaryRow'
import { PageReportsSummary } from '../../util/hooks/api/usePageReport'
import { HeaderCellHelp } from '../ContentReport/cells/HeaderCellHelp'
import { HELP_TEXTS } from '../../constants'

interface Props {
  readonly projectId: string
  readonly items: PageReportItem[]
  readonly pageReportsSummary: PageReportsSummary
  readonly sortState: PageReportSortState
  readonly topIndex: number // 先頭のインデックス
  readonly baseUrl?: string
  readonly onSort: (key: PageReportSortKey) => void
}

export function PageGrid({ projectId, items, pageReportsSummary, sortState, topIndex, baseUrl, onSort }: Props) {
  const { scopeType, scopeTypeLabel } = useScopeType()
  const countSortKey = scopeType === SCOPE_TYPE.USER ? 'page_users' : 'page_sessions'
  const allViewCount = scopeType === SCOPE_TYPE.USER ? pageReportsSummary.allUsers : pageReportsSummary.allSessions

  const isScopeTypeUser = scopeType === SCOPE_TYPE.USER

  const element = items.map((item, index) => {
    return (
      <Li data-id={item.id} key={index}>
        <PageGridItem projectId={projectId} item={item} dataIndex={topIndex + index} baseUrl={baseUrl} />
      </Li>
    )
  })

  const onSortClick = (elementKey: any) => {
    onSort(elementKey)
  }

  return (
    <Container>
      <Header>
        <Row className="url">
          <HeaderContentsUrl>
            <Main>ページ</Main>
          </HeaderContentsUrl>
        </Row>

        <Row className="view_count" onClick={() => onSortClick(countSortKey)}>
          <HeaderContents>
            <Main>{scopeTypeLabel}数</Main>
            <Sub>(対全体比率)</Sub>
          </HeaderContents>
          <HeaderCellHelp width={200}>
            {isScopeTypeUser ? HELP_TEXTS.USER_COUNT : HELP_TEXTS.SESSION_COUNT}
          </HeaderCellHelp>
          <SortButton elementKey={countSortKey} sortIcon={sortState[countSortKey]} />
        </Row>

        <Row className="page_landing_count" onClick={() => onSortClick('page_landing_count')}>
          <HeaderContents>
            <Main>ランディング数</Main>
            <Sub>(対全体比率)</Sub>
          </HeaderContents>
          <HeaderCellHelp width={150}>{HELP_TEXTS.LANDING_COUNT}</HeaderCellHelp>
          <SortButton elementKey={countSortKey} sortIcon={sortState['page_landing_count']} />
        </Row>

        <Row className="page_bounce_rate" onClick={() => onSortClick('page_bounce_rate')}>
          <HeaderContents>
            <Main>直帰率</Main>
          </HeaderContents>
          <HeaderCellHelp width={220}>{HELP_TEXTS.BOUNCE_RATE}</HeaderCellHelp>
          <SortButton elementKey={countSortKey} sortIcon={sortState['page_bounce_rate']} />
        </Row>

        <Row className="page_exit_rate" onClick={() => onSortClick('page_exit_rate')}>
          <HeaderContents>
            <Main>離脱率</Main>
          </HeaderContents>
          <HeaderCellHelp width={300}>
            {isScopeTypeUser ? HELP_TEXTS.USER_EXIT_RATE : HELP_TEXTS.SESSION_EXIT_RATE}
          </HeaderCellHelp>
          <SortButton elementKey={countSortKey} sortIcon={sortState['page_exit_rate']} />
        </Row>

        <Row className="goal">
          <VerticalSplitTopCell>
            <Main>ゴール</Main>
          </VerticalSplitTopCell>
          <VerticalSplitBottomCell>
            <Row className="page_conversions" onClick={() => onSortClick('page_conversions')}>
              <HeaderContents>
                <Main>数</Main>
                <Sub>(対全体比率)</Sub>
              </HeaderContents>
              <HeaderCellHelp width={200}>
                {isScopeTypeUser ? HELP_TEXTS.USER_GOAL_COUNT : HELP_TEXTS.SESSION_GOAL_COUNT}
              </HeaderCellHelp>
              <SortButton elementKey={'page_conversions'} sortIcon={sortState['page_conversions']} />
            </Row>
            <Row className="page_conversions_rate" onClick={() => onSortClick('page_conversions_rate')}>
              <HeaderContents>
                <Main>率</Main>
              </HeaderContents>
              <HeaderCellHelp>
                {isScopeTypeUser ? HELP_TEXTS.USER_GOAL_RATE : HELP_TEXTS.SESSION_GOAL_RATE}
              </HeaderCellHelp>
              <SortButton elementKey={'page_conversions_rate'} sortIcon={sortState['page_conversions_rate']} />
            </Row>
          </VerticalSplitBottomCell>
        </Row>
      </Header>
      <SummaryRow
        allViewCount={allViewCount}
        allLandingCount={pageReportsSummary.allLandingCount}
        allBounceRate={pageReportsSummary.allBounceRate}
        allExitRate={pageReportsSummary.allExitRate}
        allConversions={pageReportsSummary.allConversions}
        allConversionsRate={pageReportsSummary.allConversionsRate}
      />
      <Ul>{element}</Ul>
    </Container>
  )
}

const Container = styled.div`
  min-width: ${gridLayout.minWidth};
`

const Header = styled.header`
  display: flex;
  align-items: center;
  background-color: ${colors.lightCyan};
  box-sizing: border-box;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
`

const HeaderContents = styled.div``
const Main = styled.div`
  font-size: ${gridLayout.fontSize};
  font-weight: 600;
`
const Sub = styled.div`
  margin-top: 3px;
  font-size: 11px;
  color: ${colors.gray750};
`

const HeaderContentsUrl = styled(HeaderContents)`
  margin-left: ${gridLayout.row.index};
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${gridLayout.headerHeight};
  text-align: center;
  border-top: 1px solid ${GRID_BORDER_COLOR};
  border-bottom: 1px solid ${GRID_BORDER_COLOR};
  border-left: 1px solid ${GRID_BORDER_COLOR};
  box-sizing: border-box;

  &.url {
    width: 100%;
    min-width: ${gridLayoutRowGroups.url};
    justify-content: flex-start;
    border-top-left-radius: 2px;
    background-color: ${colors.lightCyan};
  }

  &.view_count {
    width: ${gridLayout.row.viewCount};
    max-width: ${gridLayout.row.viewCount};
    min-width: ${gridLayout.row.viewCount};
    background-color: ${colors.lightSalmon};
    cursor: pointer;
  }

  &.page_landing_count {
    width: ${gridLayout.row.pageLandingCount};
    max-width: ${gridLayout.row.pageLandingCount};
    min-width: ${gridLayout.row.pageLandingCount};
    background-color: ${colors.lightSalmon};
    cursor: pointer;
  }

  &.page_bounce_rate {
    width: ${gridLayout.row.pageBounceRate};
    max-width: ${gridLayout.row.pageBounceRate};
    min-width: ${gridLayout.row.pageBounceRate};
    background-color: ${colors.lightSalmon};
    cursor: pointer;
  }

  &.page_exit_rate {
    width: ${gridLayout.row.pageExitRate};
    max-width: ${gridLayout.row.pageExitRate};
    min-width: ${gridLayout.row.pageExitRate};
    background-color: ${colors.lightSalmon};
    cursor: pointer;
  }

  &.goal {
    width: calc(${gridLayout.row.pageConversions} + ${gridLayout.row.pageConversionsRate});
    max-width: calc(${gridLayout.row.pageConversions} + ${gridLayout.row.pageConversionsRate});
    min-width: calc(${gridLayout.row.pageConversions} + ${gridLayout.row.pageConversionsRate});
    background-color: ${colors.lightSalmon};
    flex-direction: column;
    border-right: 1px solid ${GRID_BORDER_COLOR};
    border-top-right-radius: 2px;
  }

  &.page_conversions {
    width: ${gridLayout.row.pageConversions};
    max-width: ${gridLayout.row.pageConversions};
    min-width: ${gridLayout.row.pageConversions};
    height: 100%;
    border-left: 0px none;
    border-bottom: 0px none;
    border-right: 1px solid ${GRID_BORDER_COLOR};
    cursor: pointer;
    text-align: right;
  }

  &.page_conversions_rate {
    width: calc(${gridLayout.row.pageConversionsRate} - 2px);
    max-width: calc(${gridLayout.row.pageConversionsRate} - 2px);
    min-width: calc(${gridLayout.row.pageConversionsRate} - 2px);
    height: 100%;
    border-left: 0px none;
    border-bottom: 0px none;
    cursor: pointer;
  }
`

const VerticalSplitCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const VerticalSplitTopCell = styled(VerticalSplitCell)`
  height: calc(${gridLayout.headerHeight} * 0.4);
`

const VerticalSplitBottomCell = styled(VerticalSplitCell)`
  height: calc(${gridLayout.headerHeight} * 0.6);
`

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  background-color: ${colors.white};

  &:hover {
    background-color: ${colors.gray50};
  }

  &.on {
    background-color: ${colors.gray200};
  }
`
