import React from 'react'
import styled from 'styled-components'
import { StepTransaction } from '../EditReport/StepTransaction'
import { colors, DATA_BAR_COLORS } from '../../../styleConstants'
import { DataDataBar } from '../../../components/DataTable'
import { FunnelStepData } from '../../../util/hooks/api/FunnelReport/types'
import { STEP_TYPES } from '../../../util/hooks/api/FunnelReport/constants'
import { InputErrorMessage } from '../../../components/errors/InputErrorMessage'

interface Props {
  steps: FunnelStepData[]
  setRefs: (refArray: (HTMLDivElement | null)[]) => void
  maxHeights: number[]
}

export function Steps({ steps, setRefs, maxHeights }: Props) {
  const stepTitleRefs = React.useRef<(HTMLDivElement | null)[]>([])

  React.useEffect(() => {
    setRefs(stepTitleRefs.current)
  }, [setRefs])

  return (
    <>
      {steps.map((step, stepIndex) => {
        const hasGoalError = step.stepType === STEP_TYPES.GOAL && !step.stepGoal?.goalId

        return (
          // FIXME: [後日実装] ホバー時にステップ詳細情報を表示
          <React.Fragment key={stepIndex}>
            {/* FIXME: [後日実装] ファネルが無効の場合はハイフン表示( funnels[].errors[] で1件以上エラー存在する場合) */}
            {stepIndex > 0 && (
              <StepTransaction transitionCount={step.transitionCount} transitionRate={step.transitionRate} />
            )}

            <StepBox hasError={hasGoalError}>
              <StepTitle
                hasError={hasGoalError}
                ref={(el) => (stepTitleRefs.current[stepIndex] = el)}
                style={{ height: maxHeights[stepIndex] }}
              >
                <div>{stepIndex + 1}.</div>
                <div>{step.name}</div>
              </StepTitle>

              {hasGoalError ? (
                <InputErrorMessageWrapper>
                  <InputErrorMessage>指定したゴールが削除されています。</InputErrorMessage>
                </InputErrorMessageWrapper>
              ) : (
                <StepAccessCount>{step.accessCount !== null ? step.accessCount.toLocaleString() : '-'}</StepAccessCount>
              )}

              {step.accessCount !== null && (
                <StyledDataDataBar
                  barPercent={step.accessCountScaledPercent ?? 0}
                  barColor={DATA_BAR_COLORS.FUNNEL_REPORT_COUNT}
                />
              )}
            </StepBox>
          </React.Fragment>
        )
      })}
    </>
  )
}

export const StepBox = styled.div<{ hasError: boolean }>`
  border: 1px solid ${colors.contentBlue.blue5};
  border-radius: 5px 5px 0 0;
  position: relative;
  ${({ hasError }) =>
    hasError &&
    `
      border-color: ${colors.disabled};
  `}
`

export const StepTitle = styled.div<{ hasError: boolean }>`
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  padding: 4px 8px;
  background-color: ${colors.bg};
  color: ${colors.link.base};
  ${({ hasError }) =>
    hasError &&
    `
      background-color: ${colors.gray325};
      color: ${colors.gray750};
  `}
  font-weight: bold;
  border-radius: 5px 5px 0 0;
  word-break: break-all;
`

export const InputErrorMessageWrapper = styled.div`
  padding: 8px 24px;
  background-color: ${colors.white};
`

export const StepAccessCount = styled.div`
  padding: 8px 0;
  background-color: ${colors.white};
  font-size: 20px;
  font-weight: bold;
  text-align: center;
`

const StyledDataDataBar = styled(DataDataBar)`
  padding: 0;
  height: 16px;
`
