import * as React from 'react'
import styled from 'styled-components'
import { AccountInfo } from '../../../GlobalState'
import { Actions, ProjectSettingsType } from '../../../pages/Settings/state'
import { ProjectInfoPanel } from './ProjectInfoPanel'
import { PvCountPanel } from './PvCountPanel'
import { TrackingCodePanel } from './TrackingCodePanel'

interface Props {
  projectId: string
  category: string
  projectErrorMessage: string | undefined
  project: ProjectSettingsType
  pageActions: Actions
  AccountInfo: AccountInfo
  trackingCode: string
}

export function ProjectInfo({
  projectId,
  category,
  projectErrorMessage,
  project,
  pageActions,
  AccountInfo,
  trackingCode,
}: Props) {
  return (
    <AccordionBoxes>
      <ProjectInfoPanel
        projectId={projectId}
        defaultExpanded={category === 'project'} // urlが settings/trackingcode の場合はデフォルト閉じる
        projectErrorMessage={projectErrorMessage}
        project={project}
        pageActions={pageActions}
        AccountInfo={AccountInfo}
      />

      <PvCountPanel
        projectId={projectId}
        defaultExpanded={category === 'project'} // urlが settings/trackingcode の場合はデフォルト閉じる
        project={project}
        pageActions={pageActions}
      />

      <TrackingCodePanel trackingCode={trackingCode} />
    </AccordionBoxes>
  )
}

const AccordionBoxes = styled.div`
  > :nth-child(n + 2) {
    margin-top: 28px;
  }
`
