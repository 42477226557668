import * as React from 'react'
import styled from 'styled-components'
import { Tab, Tabs } from '@mui/material'

import { colors } from '../../styleConstants'
import { TabIndex } from '../../pages/ContentReport/state'

interface Props {
  tabIndex: TabIndex
  onChange: (event: React.SyntheticEvent, value: TabIndex) => void
}

export function TabArea({ tabIndex, onChange }: Props) {
  return (
    <Container>
      <StyledTabs value={tabIndex} onChange={onChange}>
        <StyledTab label="全コンテンツ" />
        <StyledTab label="コンテンツ名単位" />
      </StyledTabs>
    </Container>
  )
}

const Container = styled.div`
  height: 48px;
  width: 100%;
  background-color: ${colors.contentBlue.blue2};
`

const StyledTabs = styled(Tabs).attrs({ disableRipple: true })`
  height: 40px;
  width: 100%;
  padding-left: 32px;
  align-items: flex-end;

  & .MuiTabs-flexContainer {
    gap: 4px;
  }

  & .MuiTabs-indicator {
    opacity: 0;
  }
`

const StyledTab = styled(Tab).attrs({ disableRipple: true })`
  padding: 0 16px;
  min-height: 40px;
  cursor: pointer;
  border-radius: 5px 5px 0 0;
  background-color: ${colors.contentBlue.blue2};
  font-size: 14px;
  font-weight: bold;
  color: ${colors.contentBlue.blue7};

  &:hover {
    background-color: ${colors.contentBlue.blue1};
  }

  &.Mui-selected {
    background-color: ${colors.contentBlue.blue1};
    color: ${colors.contentBlue.blue7};
  }
`
