import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'

export const Popup: React.FC<{ contents: { title: string; items: string[] }[]; direction?: 'left' | 'right' }> = ({
  children,
  contents,
  direction = 'left',
}) => {
  const [isPopupVisible, setIsPopupVisible] = React.useState(false)

  const handleMouseEnter = () => {
    setIsPopupVisible(true)
  }

  const handleMouseLeave = () => {
    setIsPopupVisible(false)
  }
  return (
    <Container onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
      {children}
      {isPopupVisible && (
        <PopupArea direction={direction}>
          {contents.map((content, i) => {
            return (
              <React.Fragment key={`${content}${i}`}>
                {i !== 0 && <Border />}
                <Title>{content.title}</Title>
                <Body>
                  {content.items.map((item) => {
                    return <BodyText key={item}>{item}</BodyText>
                  })}
                </Body>
              </React.Fragment>
            )
          })}
        </PopupArea>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`
const PopupArea = styled.div<{ direction: 'left' | 'right' }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: calc(100%);
  ${({ direction }) => (direction === 'left' ? 'left: 0;' : 'right: 0;')}
  z-index: 1;
  max-width: 400px;
  padding: 12px;
  background: ${colors.white};
  border-top: solid 16px rgb(255, 255, 255, 0);
  background-clip: padding-box;
  word-break: keep-all;
  overflow-wrap: break-word;
  white-space: normal;
  filter: drop-shadow(0px 0px 1px ${colors.contentBlue.blue7});

  &::before {
    content: '';
    position: absolute;
    top: -15px;
    ${({ direction }) => (direction === 'left' ? 'left: 26px;' : 'right: 26px;')}
    width: 26px;
    height: 16px;
    background: ${colors.white};
    clip-path: polygon(50% 30%, 0% 100%, 100% 100%);
  }
`

const Title = styled.p`
  margin: 0;
  color: ${colors.contentBlue.blue7};
  font-weight: bold;
  font-size: 12px;
  line-height: 1.5;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const BodyText = styled.p`
  margin: 0;
  color: ${colors.black};
  font-size: 14px;
  line-height: 1.5;
`

const Border = styled.hr`
  height: 1px;
  border: none;
  width: 100%;
  background-color: ${colors.gray250};
`
