import { useMutation, UseMutationOptions } from '@tanstack/react-query'

import { request } from '../../request'
import { ContentTag } from './useContentReport'

type Payload = { contentIds: number[]; tagNames: string[] }
type ApiResponse = {
  content_tags: ContentTag[]
}

export const useUpdateMultipleContentsTags = ({
  projectId,
  options,
}: {
  projectId: string
  options: UseMutationOptions<ContentTag[], string, Payload, void>
}) => {
  return useMutation({
    mutationFn: async (payload: Payload) => {
      const apiPayload = { partial_content_ids: payload.contentIds, content_tag_names: payload.tagNames }
      const response = await request<ApiResponse>(
        'POST',
        `/api/projects/${projectId}/content_tags_partial_contents/`,
        true,
        JSON.stringify(apiPayload),
      )
      return response.content_tags
    },
    ...options,
  })
}
