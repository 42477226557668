import * as React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { HelpTooltip } from '../../Tooltip/IconTooltip'

interface Props {
  readonly label: string
  readonly value: string
  readonly helpText: string
}

export function PageSummaryBox({ label, value, helpText }: Props) {
  return (
    <Container>
      <LabelArea>
        <Label>{label}</Label>
        <HelpTooltip width={240} iconSize="16" borderRadius="20px" iconColor={`${colors.lightBlue}`}>
          <HelpPopupText>{helpText}</HelpPopupText>
        </HelpTooltip>
      </LabelArea>
      <Value>{value}</Value>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  background-color: ${colors.white};
  padding: 0 16px;
`

const LabelArea = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.div`
  font-size: 12px;
  font-weight: bold;
`

const HelpPopupText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const Value = styled.div`
  font-size: 16px;
  font-weight: bold;
`
