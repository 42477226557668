import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { request } from '../../request'

type UpdateMultipleContentPayload = { name: string; partial_content_ids: Array<number> }
export type UpdateMultipleContentResponse = {
  managed_contents: Array<{
    id: number
    partial_content_id: number
    name: string
  }>
}

export const useUpdateMultipleContent = ({
  projectId,
  options,
}: {
  projectId: string
  options: UseMutationOptions<UpdateMultipleContentResponse, string, UpdateMultipleContentPayload, void>
}) => {
  const mutationResult = useMutation({
    mutationFn: async (body: UpdateMultipleContentPayload) =>
      await request<UpdateMultipleContentResponse>(
        'POST',
        `/api/projects/${projectId}/managed_contents_list_upsert/`,
        true,
        JSON.stringify(body),
      ),
    ...options,
  })
  return mutationResult
}
